import produce from "immer"

const initialState = false

export const VideoReducer = (state = initialState, action) => {

    switch (action.type) {
        case "ADD_SLIDE_CHAPTER":
            let obj = { ...action.payload.script[0] }
            obj.slides = action.payload.slides.reverse()
            obj.actionType = ""
            return obj

        case "ADD_SLIDES":
            return produce(state, (draft) => {
                draft.slides = action.payload
            })

        case "NEW_SLIDE_ADD":
            return produce(state, (draft) => {
                draft.slides.push(action.payload)
            })

        case "UNMOUNT_SLIDES":
            return {
                ...state,
                slides: action.payload
            }

        case "UPDATE_TEXT_FONTSTYLE":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.fontFamily = action.payload.data
            })

        case "UPDATE_TEXT_FONTSIZE":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.fontSize = +action.payload.data
            })



        case "UPDATE_TEXT_FONTBOLD":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.fontWeight = action.payload.data
            })


        case "UPDATE_TEXT_FONTITALIC":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.fontStyle = action.payload.data
            })


        case "UPDATE_TEXT_FONTTRANSFORM":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.textTransform = action.payload.data
            })


        case "UPDATE_TEXT_FONTDECO":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.textDecoration = action.payload.data
            })


        case "UPDATE_TEXT_FONTALIGN":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.textAlign = action.payload.data
            })


        case "UPDATE_TEXT_FONTLINEHEIGHT":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.lineHeight = action.payload.data
            })


        case "UPDATE_TEXT_FONTOPACITY":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.opacity = action.payload.data
            })


        case "UPDATE_TEXT_FONTCOLOR":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.fontColor = action.payload.data
            })


        case "UPDATE_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.backgroundColor = action.payload.data
            })


        case "REMOVE_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.selectedTextIndex].style.backgroundColor = ""
            })


        case "APPLY_TO_ALL":
            return produce(state, (draft) => {
                draft.slides.forEach((slide) => {
                    slide.layers.forEach((layer) => {
                        layer.style = action.payload
                    })
                })
            })

        // VoiceOver Reducers 

        case "UPDATE_TTS_TEXT":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].voiceOver.meta.text = action.payload.text
            })



        case 'ADD_TTS_DATA':
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].voiceOver = {
                    ...draft.slides[action.payload.selectedSlideIndex].voiceOver,
                    enable: action.payload.enable,
                    src: action.payload.meta.src,
                    type: "mp3",
                    meta: action.payload.meta.meta,
                    duration: action.payload.meta.duration
                }
            })


        case 'REMOVE_TTSAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].voiceOver = {
                    ...draft.slides[action.payload.selectedSlideIndex].voiceOver,
                    enable: false,
                    src: '',
                    meta: action.payload.tts,
                    duration: 0
                }
            })



        case "ADD_ALL_TEXT":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].voiceOver = {
                    ...draft.slides[action.payload.index].voiceOver,
                    meta: {
                        ...draft.slides[action.payload.index].voiceOver.meta,
                        text: action.payload.text
                    }
                }
            })


        case "UPDATE_TTS1_TEXT":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].voiceOver = {
                    ...draft.slides[action.payload.index].voiceOver,
                    meta: {
                        ...draft.slides[action.payload.index].voiceOver.meta,
                        text: action.payload.text
                    }
                }
            })


        case "UPDATE_GENERATED_TTS":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].voiceOver = {
                    ...draft.slides[action.payload.index].voiceOver,
                    enable: true,
                    type: "mp3",
                    duration: action.payload.val.duration,
                    src: action.payload.val.url,
                    meta: {
                        ...draft.slides[action.payload.index].voiceOver.meta,
                        languageId: action.payload.langData.lang,
                        voiceId: action.payload.langData.voice
                    }
                }
            })


        case "UPDATE_TEXT_TRANSLATE":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].voiceOver = {
                    ...draft.slides[action.payload.index].voiceOver,
                    meta: {
                        ...draft.slides[action.payload.index].voiceOver.meta,
                        translateText: action.payload.newText,

                    }
                }
            })


        case "REVERT_TRANSLATE":
            return produce(state, (draft) => {
                draft.slides[action.payload].voiceOver = {
                    ...draft.slides[action.payload].voiceOver,
                    meta: {
                        ...draft.slides[action.payload].voiceOver.meta,
                        translateText: "",
                    }
                }
            })


        // Background Music  
        case 'ADD_BGAUDIO_DATA':
            return produce(state, (draft) => {
                draft.audio = {
                    ...draft.audio,
                    source: action.payload.url,
                    enable: true,
                    type: "mp3",
                    duration: action.payload.dur
                }
            })


        case 'REMOVE_BGAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.audio = {
                    ...draft.audio,
                    source: "",
                    enable: false,
                    type: ""
                }
            })


        // Background IMAGE/VIDEO

        case 'UPDATE_BG_IMAGE':
            return produce(state, (draft) => {
                draft.slides[action.payload.index].background = {
                    ...draft.slides[action.payload.index].background,
                    type: "image",
                    src: action.payload.file.url,
                    poster: action.payload.file.thumbnail,
                }
            })


        case 'UPDATE_BG_VIDEO':
            return produce(state, (draft) => {
                draft.slides[action.payload.index].background = {
                    ...draft.slides[action.payload.index].background,
                    type: "video",
                    src: action.payload.file.url,
                    poster: action.payload.file.thumbnail,
                }
                draft.slides[action.payload.index].bgDuration = action.payload.file.duration
            })


        case 'UPDATE_BG_COLOR':
            return produce(state, (draft) => {
                draft.slides[action.payload.index].background = {
                    ...draft.slides[action.payload.index].background,
                    type: "color",
                    src: action.payload.file,
                    poster: action.payload.file,
                    hex: action.payload.hex
                }
            })



        // LAYERS REDUCERS 

        case "UPDATE_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers.forEach((layer) => {
                    layer.isSelected = false
                })
                draft.slides[action.payload.selectedSlideIndex].layers[action.payload.index].isSelected = true
            })

        // TEXT LAYER 

        case "ADD_TEXT_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers.forEach((layer) => {
                    layer.isSelected = false
                })
                draft.slides[action.payload.slideIndex].layers.push(action.payload.layer)
            })

        case "UPDATE_LAYER_TEXT":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].text = action.payload.text
            })

        //MEDIA LAYER

        case "ADD_MEDIA_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].layers.forEach((layer) => {
                    layer.isSelected = false
                })
                draft.slides[action.payload.index].layers.push(action.payload.objData)
            })


        case "UPDATE_LAYER_IMAGE":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "image"
            })

        case "UPDATE_LAYER_VIDEO":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = {
                    ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex],
                    src: action.payload.val.url,
                    type: "video",
                    size: {
                        ...draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size,
                        height: action.payload.val.height ? action.payload.val.height : 150,
                        width: action.payload.val.width ? action.payload.val.width : 280
                    }
                }
            })

        case "REMOVE_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].layers.splice(action.payload.layerIndex, 1)
                if (draft.slides[action.payload.selectedSlideIndex].layers.length > 0) {
                    draft.slides[action.payload.selectedSlideIndex].layers[0].isSelected = true
                }
            })


        case "COPY_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex].isSelected = false
                draft.slides[action.payload.slideIndex].layers.push(action.payload.layerData)
            })

        // VIdeo Tiles reducer

        case 'UPDATE_SLIDES_DATA':
            return produce(state, (draft) => {
                draft.slides[action.payload.slideIndex].isSelected = "0"
                draft.slides[action.payload.index].isSelected = "1"
            })

        case 'ON_REORDER_DATA':
            return produce(state, (draft) => {
                const [removed] = draft.slides.splice(action.payload.source, 1);
                draft.slides.splice(action.payload.dest, 0, removed);
            })

        case 'UPDATE_SELECTED_TILES':
            return produce(state, (draft) => {
                let index = draft.slides.findIndex(({ isSelected }) => isSelected === "1");
                draft.slides[index].isSelected = "0"
                draft.slides[action.payload].isSelected = "1"
            })



        case "DELETE_TILE":
            return produce(state, (draft) => {

                draft.slides[+action.payload.deleteIndex].isDeleted = "1"
                if (action.payload.newIndex !== false) {
                    draft.slides[+action.payload.newIndex].isSelected = "1"
                }
                draft.actionType = "DELETE_TILE"
            })
        case "REMOVE_ACTION_TYPE":
            return produce(state, (draft) => {
                draft.actionType = ""
            })

        // SETTING REDUCERS


        case 'UPDATE_STATUS_L&W':
            return produce(state, (draft) => {
                if (action.payload.type === 'logo') {
                    draft.logo.enable = action.payload.enable
                }
                else if (action.payload.type === 'watermark') {
                    draft.watermark.enable = action.payload.enable
                }
            })





        case 'ADD_LOGO':
            return produce(state, (draft) => {
                draft.logo.src = action.payload.data
            })

        case 'ADD_WATERMARK':
            return produce(state, (draft) => {
                draft.watermark.src = action.payload.data
            })

        case 'REMOVE_LOGO_LAYER':
            return produce(state, (draft) => {
                draft.logo.src = ""
                draft.logo.postion = 'top-left'
            })


        case 'REMOVE_WATERMARK_LAYER':
            return produce(state, (draft) => {
                draft.watermark.src = ""
                draft.watermark.postion = 'top-left'
            })

        case 'UPDATE_LOGO_POSITION':
            return produce(state, (draft) => {
                draft.logo = {
                    ...draft.logo,
                    pos: action.payload.data,
                    positionX: action.payload.dimension.x,
                    positionY: action.payload.dimension.y

                }
            })


        case 'UPDATE_WATERMARK_POSITION':
            return produce(state, (draft) => {
                draft.watermark = {
                    ...draft.watermark,
                    pos: action.payload.data,
                    positionX: action.payload.dimension.x,
                    positionY: action.payload.dimension.y

                }
            })


        case 'UPDATE_SLIDE_DURATION':
            return produce(state, (draft) => {
                const selectedTile = draft.slides.findIndex(({ isSelected }) => isSelected === "1")
                draft.slides[selectedTile].duration = parseInt(action.payload)
            })


        case 'CHANGE_BG_VOLUME':
            return produce(state, (draft) => {
                draft.audio = {
                    ...draft.audio,
                    volume: parseInt(action.payload)
                }
            })


        case 'CHANGE_VOICE_VOLUME':
            return produce(state, (draft) => {
                draft.slides[action.payload.selectedSlideIndex].voiceOver = {
                    ...draft.slides[action.payload.selectedSlideIndex].voiceOver,
                    volume: parseInt(action.payload.value)
                }
            })


        // INTRO / OUTRO 

        case 'SET_INTRO_STATUS':
            return produce(state, (draft) => {
                draft.intro.enable = action.payload
            })

        case 'SET_OUTRO_STATUS':
            return produce(state, (draft) => {
                draft.outro.enable = action.payload
            })


        case 'REMOVE_INTRO':
            return produce(state, (draft) => {
                draft.intro = {
                    ...draft.intro,
                    src: '',
                    thumbnail: '',
                    name: ''
                }
            })


        case 'REMOVE_OUTRO':
            return produce(state, (draft) => {
                draft.outro = {
                    ...draft.outro,
                    src: '',
                    thumbnail: '',
                    name: ''
                }
            })

        case 'UPDATE_INTRO': {
            return produce(state, (draft) => {
                draft.intro = {
                    ...draft.intro,
                    src: action.payload.url,
                    thumbnail: action.payload.thumbData,
                    name: action.payload.nameData
                }
            })

        }
        case 'UPDATE_OUTRO': {
            return produce(state, (draft) => {
                draft.outro = {
                    ...draft.outro,
                    src: action.payload.url,
                    thumbnail: action.payload.thumbData,
                    name: action.payload.nameData
                }
            })

        }

        case "ADD_TRANSITION":
            return produce(state, (draft) => {
                draft.slides[action.payload.index].transition = {
                    ...draft.slides[action.payload.index].transition,
                    simpleTransition: action.payload.name,
                    enable: true
                }
            })



        // LAYER EDITABLE

        case "RESIZE_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideI].layers[action.payload.layerI].size = {
                    ...draft.slides[action.payload.slideI].layers[action.payload.layerI].size,
                    width: action.payload.w,
                    height: action.payload.h
                }
            })


        case "REPOSITION_LAYER":
            return produce(state, (draft) => {
                draft.slides[action.payload.slideI].layers[action.payload.layerI].position = {
                    ...draft.slides[action.payload.slideI].layers[action.payload.layerI].position,
                    left: action.payload.l,
                    top: action.payload.t
                }
            })


        case "LAYER_DOWN":
            return produce(state, (draft) => {
                let temp = draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1]
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1] = temp
            })


        case "LAYER_UP":
            return produce(state, (draft) => {
                let temp = draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1]
                draft.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1] = temp
            })


        default: {
            return state
        }
    }
}