import React, { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { Invoice3style } from "./style";
import { VscDiffAdded, VscDiffRemoved } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onInvoiceSave1, onSetInvoice, onSetInvoiceTable, onUpdateInvoiceData} from "../../../Actions/ResourceAction";
import { onFecthCards } from "../../../Actions/ResourceAction";

const Invoice_4 = () => {

  const invoice = useSelector(state => state.resource.invoice)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const invoiceId = queryString.parse(location.search).id
  const aId = queryString.parse(location.search).aId
  const cId = queryString.parse(location.search).cId

  const [loader,setLoader] = useState(false)
  const [dataVal, setDataVal] = useState(false)
  const [state, setState] = useState({
    invoiceNo: "123456",
    date: "21 Sep 2021",
    name: "Jhon Smith",
    name2: "Theme Vessel",
    name3: "Apexo Inc",
    address1: " 21-12 Green Street, Meherpur, Bangladesh",
    invoiceFrom: "Animas Roky",
    email1: "info@themevessel.com ",
    email2: "billing@apexo.com",
    fromAddress: "169 Teroghoria, Bangladesh ",
    termCond:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been typesetting industry. Lorem Ipsum",
    phoneNo: "123-456-789",
    accoumtNo: " 00 123 647",
    accountName: " XYZ",
    branchName: " xyz",
    lastEmail: "info@themevessel.com",
    lastAddress: "169, adc England,",
    tax: "0.00",
    taxActive: true,
    currency: "$",
    taxPercent: "0%",
  });

  const [table, setTable] = useState([
    {
      item: "Fruit Flayer Design",
      price: "0.00",
      quantity: "0",
      amount: "0.00",
    },
  ]);

  const [total, setTotal] = useState({
    subTotal: 0,
    grandTotal: 0,
  });

  const [btn, setBtn] = useState({
    add: false,
    minus: null,
    taxShow: false,
  });

  const [id,setId] = useState({
    aId:aId,
    cId:cId
  })

  const handleChange = (e, type) => {
    const { value } = e.target;
    setState({
      ...state,
      [type]: value,
    });
     dispatch(onUpdateInvoiceData(type, value,))
  };

  const handleTable = (e, index, type) => {
    const { value } = e.target;
    if (index !== undefined) {
      let newData = [...table];
      newData[index] = {
        ...newData[index],
        [type]: value,
    }

      if (type === "quantity" || type === "price") {
        newData[index] = {
          ...newData[index],
          amount: (
            parseFloat(newData[index].price) * +newData[index].quantity
          ).toFixed(2),
        };
      } setTable(newData);
      dispatch(onSetInvoiceTable(newData));
    }
  };

  const handleAddrow = () => {
    setTable([
      ...table,
      {
        item: "Fruit Flayer Design",
        price: "0.00",
        quantity: "0",
        amount: "0.00",
      },
    ]);
  };

  const handleRemoveRow = (ind) => {
    const updateRow = table.filter((item, id) => {
      return ind !== id;
    });
    setTable(updateRow);
  };

  const addMouseHover = (id) => {
    setBtn({
      ...btn,
      add: true,
      minus: false,
    });
  };

  const addMouseLeave = () => {
    setBtn({
      ...btn,
      add: false,
      minus: false,
    });
  };

  const removeMouseHover = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: id,
    });
  };

  const removeMouseLeave = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: null,
    });
  };

  const handleTax = (val, type) => {
    setState({
      ...state,
      [type]: val,
    });
  };

  useEffect(() => {
    let grandtotal = 0,
      subtotal = 0;
    table.forEach((curElem) => {
      subtotal = subtotal + parseFloat(curElem.amount);
    });
    grandtotal = subtotal + parseFloat(state.tax);
    if (!state.taxActive) {
      grandtotal = grandtotal - parseFloat(state.tax);
    }

    setTotal({
      ...total,
      subTotal: subtotal,
      grandTotal: grandtotal,
    });
  }, [table,state.taxActive, state]);

  const fetchData = () => {
    let data = {
        businessCardId: invoiceId
    }
    dispatch(onFecthCards(data, setLoader, setDataVal))
}

useEffect(() => {
  if (id) {
      fetchData()
  }
}, [id])

useEffect(() => {
    if (invoice.data === "") {
      dispatch(onSetInvoice({ ...state, table }))
    } else {
      setState(invoice.data)
      setTable(invoice.data.table)
    }
  }, [invoice])

  return (
    <Invoice3style>
      <div className="invoice-1 invoice-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="invoice-inner clearfix">
                <div className="invoice-info clearfix" id="invoice_wrapper">
                  <div className="invoice-headar">
                    <div className="row g-0">
                      <div className="col-sm-6">
                        <div className="invoice-logo"></div>
                      </div>
                      <div className="col-sm-6 invoice-id">
                        <div className="info">
                          <h1 className="color-white inv-header-1">Invoice</h1>
                          <p className="color-white mb-1">
                            Invoice Number{" "}
                            <span>
                              <ContentEditable
                                html={state.invoiceNo}
                                tagName="span"
                                onChange={(e) => handleChange(e, "invoiceNo")}
                              />
                            </span>
                          </p>
                          <p className="color-white mb-0">
                            Invoice Date
                            <span>
                              <ContentEditable
                                html={state.date}
                                tagName="span"
                                onChange={(e) => handleChange(e, "date")}
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-top">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="invoice-number mb-30">
                                <h4 className="inv-title-1">Invoice To</h4>
                                <h2 className="name mb-10">
                                  <ContentEditable
                                    html={state.name}
                                    tagName="span"
                                    onChange={(e) => handleChange(e, "name")}
                                  />
                                </h2>
                                <p className="invo-addr-1">
                                  <ContentEditable
                                    html={state.name2}
                                    tagName="span"
                                    onChange={(e) => handleChange(e, "name2")}
                                  />

                                  <ContentEditable
                                    html={state.email1}
                                    tagName="span"
                                    onChange={(e) => handleChange(e, "email1")}
                                  />

                                  <ContentEditable
                                    html={state.address1}
                                    tagName="span"
                                    onChange={(e) =>
                                      handleChange(e, "address1")
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="invoice-number mb-30">
                                <div className="invoice-number-inner">
                                  <h4 className="inv-title-1">Invoice From</h4>
                                  <h2 className="name mb-10">
                                    <ContentEditable
                                      html={state.invoiceFrom}
                                      tagName="span"
                                      onChange={(e) =>
                                        handleChange(e, "invoiceFrom")
                                      }
                                    />
                                  </h2>
                                  <p className="invo-addr-1">
                                    <ContentEditable
                                      html={state.name3}
                                      tagName="span"
                                      onChange={(e) => handleChange(e, "name3")}
                                    />

                                    <ContentEditable
                                      html={state.email2}
                                      tagName="span"
                                      onChange={(e) =>
                                        handleChange(e, "email2")
                                      }
                                    />

                                    <ContentEditable
                                      html={state.fromAddress}
                                      onChange={(e) =>
                                        handleChange(e, "fromAddress")
                                      }
                                    />
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="invoice-center">
                          <div className="table-responsive">
                            <table className="table mb-0 table-striped invoice-table">
                              <thead
                                className="bg-active"
                                onMouseOver={addMouseHover}
                                onMouseLeave={addMouseLeave}
                              >
                                <tr className="tr">
                                  <th>No.</th>
                                  <th className="pl0 text-start">
                                    Item Description
                                  </th>
                                  <th className="text-end">Price</th>
                                  <th className="text-center">Quantity</th>
                                  <th className="text-end">
                                    Amount
                                    {btn.add ? (
                                      <VscDiffAdded
                                        style={{
                                          position: "absolute",
                                          cursor: "pointer",
                                          marginLeft: "15px",
                                        }}
                                        onClick={handleAddrow}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr className="tr">
                                                                        <td>
                                                                            <div className="item-desc-1">
                                                                                <span>01</span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="pl0">Businesscard Design</td>
                                                                        <td className="text-center">$300</td>
                                                                        <td className="text-center">2</td>
                                                                        <td className="text-end">$600.00</td>
                                                                    </tr> */}

                                {table.length>0 ?
                                table.map((elem, index) => {
                                  return (
                                    <>
                                      <tr
                                        className="tr"
                                        key={index}
                                        onMouseOver={() =>
                                          removeMouseHover(index)
                                        }
                                        onMouseLeave={() =>
                                          removeMouseLeave(index)
                                        }
                                      >
                                        <td>
                                          <div className="item-desc-1">
                                            <span>{index + 1}</span>
                                          </div>
                                        </td>
                                        <td className="pl0">
                                          <ContentEditable
                                            html={elem.item}
                                            onChange={(e) =>
                                              handleTable(e, index, "item")
                                            }
                                          />
                                        </td>
                                        <td className="text-end ">
                                          <ContentEditable
                                            html={state.currency}
                                            tagName="span"
                                            onChange={(e) =>
                                              handleChange(e, "currency")
                                            }
                                          />
                                          <ContentEditable
                                            html={elem.price}
                                            tagName="span"
                                            onChange={(e) =>
                                              handleTable(e, index, "price")
                                            }
                                          />
                                        </td>
                                        <td className="text-center">
                                          <ContentEditable
                                            html={elem.quantity}
                                            tagName="span"
                                            onChange={(e) =>
                                              handleTable(e, index, "quantity")
                                            }
                                          />
                                        </td>
                                        <td className="text-end">
                                          <ContentEditable
                                            html={state.currency}
                                            tagName="span"
                                            onChange={(e) =>
                                              handleChange(e, "currency")
                                            }
                                          />
                                          {elem.amount}
                                          {btn.minus === index ? (
                                            <VscDiffRemoved
                                              style={{
                                                position: "absolute",
                                                cursor: "pointer",
                                                marginLeft: "15px",
                                              }}
                                              onClick={() =>
                                                handleRemoveRow(index)
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }):""}
                                {/* <tr className="bg-grea">
                                                                        <td>
                                                                            <div className="item-desc-1">
                                                                                <span>02</span>

                                                                            </div>
                                                                        </td>
                                                                        <td className="pl0">Fruit Flayer Design</td>
                                                                        <td className="text-center">$400</td>
                                                                        <td className="text-center">1</td>
                                                                        <td className="text-end">$60.00</td>
                                                                    </tr> */}

                                <tr className="tr2">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-center">SubTotal</td>
                                  <td className="text-end">
                                    <ContentEditable
                                      html={state.currency}
                                      tagName="span"
                                      
                                      onChange={(e) =>
                                        handleChange(e, "currency")
                                      }
                                    />
                                    {total.subTotal.toFixed(2)}
                                  </td>
                                </tr>
                                {state.taxActive ? (
                                  <tr
                                    className="tr2"
                                    onMouseEnter={() =>
                                      setBtn({
                                        ...btn,
                                        taxShow: true,
                                      })
                                    }
                                    onMouseLeave={() =>
                                      setBtn({
                                        ...btn,
                                        taxShow: false,
                                      })
                                    }
                                  >
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center">
                                      Tax (
                                      <ContentEditable
                                        html={state.taxPercent}
                                       
                                        tagName="span"
                                        onChange={(e) =>
                                          handleChange(e, "taxPercent")
                                        }
                                      />
                                      )
                                    </td>
                                    <td className="text-end">
                                      <ContentEditable
                                        html={state.currency}
                                        
                                        tagName="span"
                                        onChange={(e) =>
                                          handleChange(e, "currency")
                                        }
                                      />
                                      <ContentEditable
                                        html={state.tax}
                              
                                        tagName="span"
                                        onChange={(e) => handleChange(e, "tax")}
                                      />
                                      {btn.taxShow ? (
                                        <IoCloseCircleOutline
                                          style={{
                                            position: "absolute",
                                            cursor: "pointer",
                                            marginLeft: "20px",
                                          }}
                                          size={16}
                                          onClick={() =>
                                            handleTax(false, "taxActive")
                                          }
                                          title="Remove Tex"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                <tr className="tr2">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-center f-w-600 active-color">
                                    Grand Total
                                  </td>
                                  <td className="f-w-600 text-end active-color">
                                    <ContentEditable
                                      html={state.currency}
                                      
                                      tagName="span"
                                      onChange={(e) =>
                                        handleChange(e, "currency")
                                      }
                                    />
                                    {total.grandTotal.toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="invoice-bottom mt-4">
                          <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-7">
                              <div className="mb-30 dear-client">
                                <h3 className="inv-title-1">
                                  Terms & Conditions
                                </h3>

                                <ContentEditable
                                  html={state.termCond}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "termCond")}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-5">
                              <div className="mb-30 payment-method">
                                <h3 className="inv-title-1">Payment Method</h3>
                                <ul className="payment-method-list-1 text-14 ">
                                  <li>
                                    <strong>Account No:</strong>
                                    <ContentEditable
                                      html={state.accoumtNo}
                                      tagName="span"
                                      onChange={(e) =>
                                        handleChange(e, "accoumtNo")
                                      }
                                    />
                                  </li>
                                  <li className="">
                                    <strong>Account Name:</strong>
                                    <ContentEditable
                                      html={state.accountName}
                                      tagName="span"
                                      onChange={(e) =>
                                        handleChange(e, "accountName")
                                      }
                                    />
                                  </li>
                                  <li>
                                    <strong>Branch Name:</strong>
                                    <ContentEditable
                                      html={state.branchName}
                                      tagName="span"
                                      onChange={(e) =>
                                        handleChange(e, "branchName")
                                      }
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="invoice-contact clearfix">
                          <div className="row g-0">
                            <div className="col-lg-9 col-md-11 col-sm-12">
                              <div className="contact-info">
                                <a href="tel:+55-4XX-634-7071">
                                  <i className="fa fa-phone"></i> +00
                                  <ContentEditable
                                    html={state.phoneNo}
                                    tagName="span"
                                    onChange={(e) => handleChange(e, "phoneNo")}
                                  />
                                </a>
                                <a href="tel:info@themevessel.com">
                                  <i className="fa fa-envelope"></i>

                                  <ContentEditable
                                    html={state.phoneNo}
                                    tagName="span"
                                    onChange={(e) =>
                                      handleChange(e, "lastEMail")
                                    }
                                  />
                                </a>
                                <a
                                  href="tel:info@themevessel.com"
                                  className="mr-0 d-none-580"
                                >
                                  <i className="fa fa-map-marker"></i>
                                  <ContentEditable
                                    html={state.phoneNo}
                                    tagName="span"
                                    onChange={(e) =>
                                      handleChange(e, "lastAddress")
                                    }
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="btn-sec text-left" style={{position:"absolute",marginTop:"25px"}}>
        <button className="demoLink" onClick={handleSave}>
          {loader ? <>Saving Invoice <i className="fa fa-spinner fa-spin mx-1" /></> : "Save Invoice"}
        </button>
    </div> */}
    </Invoice3style>
  );
};
export default Invoice_4;
