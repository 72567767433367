import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cardImage from "../../../images/image.png"
import CreateModal from './CreateModal';
import ResourceListTable from './ResourceListTable';
import clickIcon from '../../../images/clickIcon.png'
import fb from '../../../images/fbIcon.png'
import yb from '../../../images/YT-DEFAULT.png'
import googleIcon from "../../../images/googleIcon.png"
import { useSelector } from 'react-redux';

let videoType = ""
const CreateResources = ({ aId, cId }) => {
    const auth = useSelector((state) => state.auth);

    const [show, setShow] = useState(false)

    const handleShow = (type) => {
        videoType = type
        setShow(true)
    }
    const handleClose = () => setShow(false)

    return (
        <> {auth.user.isClient==="0"?
        <>
            <div className="business-top">
                <h2>Create Resources</h2>
            </div>
            <hr style={{ opacity: "1", color: "var(--border-color)" }} />
            
           
            <div className="business-list">
                <div className="social-list">
                    <ul>
                        <li>
                            <div className="social-list-single">
                                <Link className="fullLink" to={`/agency/create-landing-page?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Landing Page</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <span className="fullLink cursor-pointer" onClick={() => handleShow("fb")}></span>
                                <div className="social-list-txt">Create Facebook Ads</div>
                                <div className="social-list-img"><img src={fb} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <span className="fullLink cursor-pointer" onClick={() => handleShow("blog")}></span>
                                <div className="social-list-txt ">Create Blog Articles</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <span className="fullLink cursor-pointer" onClick={() => handleShow("youtube")}></span>
                                <div className="social-list-txt">Create Youtube Videos</div>
                                <div className="social-list-img"><img src={yb} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <span className="fullLink cursor-pointer" onClick={() => handleShow("adds")}></span>
                                <div className="social-list-txt">Create Video Ads</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <span className="fullLink cursor-pointer" onClick={() => handleShow("go")}></span>
                                <div className="social-list-txt">Create Google Ads</div>
                                <div className="social-list-img"><img src={googleIcon} alt="" /></div>
                            </div>
                        </li>

                        <li>
                            <div className="social-list-single">
                                <Link className="fullLink" to={`/agency/create-business-card?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Business Card </div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <Link className="fullLink" to={`/agency/create-letter-head?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Letter Head</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <Link className="fullLink" to={`/agency/create-invoice?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Invoices</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single">
                                <Link className="fullLink" to={`/agency/create-envelope?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Envelopes</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                  
                        <li>
                            <div className="social-list-single">
                                <Link className="fullLink" to={`/agency/create-social-media?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Social Media Covers</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single cursor-pointer" onClick={() => handleShow("press")}>
                                <div className="social-list-txt">Create Press Releases</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single cursor-pointer">
                                <Link className="fullLink" to={`/agency/create-image-page?id=${aId}&cId=${cId}`}></Link>
                                <div className="social-list-txt">Create Image</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div className="social-list-single cursor-pointer" onClick={() => handleShow("lead")}>
                                <div className="social-list-txt">Create Lead Magnet</div>
                                <div className="social-list-img"><img src={clickIcon} alt="" /></div>
                            </div>
                        </li>
                    </ul>
                </div>
                      

            </div></>:""}
            <div className="business-top">
                <h2> Resources List</h2>
            </div>
            <hr style={{ opacity: "1", color: "var(--border-color)" }} />
            <div className="business-list">
                <div className="row">
                    <div className="col-md-12">
                        <ResourceListTable
                            aId={aId}
                            cId={cId}
                        />
                    </div>
                </div>
            </div>

            <CreateModal
                show={show}
                handleClose={handleClose}
                type={videoType}
                aId={aId}
                cId={cId}
            />


        </>
    )
}

export default CreateResources