import React, { useEffect, useState } from 'react'
import { BsCheckCircle, BsUpload, BsFillTrashFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { onDeleteImage, onFecthUploadImage, onUploadImageLibrary } from '../../../Actions/ClientsAction'
import SweetAlertTwo from '../../SweetAlertTwo'

const UploadImage = (props) => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)

    const [imageList, setImageList] = useState([])
    const [loader, setLoader] = useState({
        imageLoader: false,
        fetchLoader: false,
        saveLoader: false
    })

    const [selected, setSelected] = useState({
        index: -1,
        data: {},
    })

    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })
    const onDelete = (e, id) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const fecthImageList = () => {

        let data
        if (props.aId && props.cId) {
            data = {
                type: "images",
                agencyId: props.aId,
                clientId: props.cId
            }
        } else {
            data = {
                type: "images",
                agencyId: landingData.agencyId,
                clientId: landingData.clientId
            }
        }
        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(onFecthUploadImage(data, setImageList, loader, setLoader))
    }

    const onUploadFile = (e) => {
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        const fileData = e.target.files[0]
        if (imageType.includes(fileData.type)) {
            if (fileData.size < 5000000) {
                setLoader({
                    ...loader,
                    imageLoader: true
                })
                const formData = new FormData()
                formData.append('upload_type', "images")
                formData.append('file', fileData)
                formData.append('agencyId', landingData.agencyId ? landingData.agencyId : props.aId)
                formData.append('clientId', landingData.clientId ? landingData.clientId : props.cId)
                dispatch(onUploadImageLibrary(formData, fecthImageList, loader, setLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size is 5MB!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "This file type is not valid. Please use JPG, JPEG or PNG file only!",
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const handleChoose = (curElem, index) => {
        setSelected({
            ...selected,
            index: index,
            data: curElem
        })
    }
    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            if (props.iconIndex !== undefined) {
                props.callbackFun(selected.data, props.iconIndex)
            } else {
                props.callbackFun(selected.data)
            }

            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);
    }

    const handleDeleteImage = (Swal) => {
        const data = new FormData()
        data.append("id", info.id)
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteImage(data, fecthImageList, Swal))
    }

    useEffect(() => {
        if (landingData.agencyId && landingData.clientId) {
            fecthImageList()
        }
        if (props.aId && props.cId) {
            fecthImageList()
        }
    }, [landingData])

    return (
        <>
            <div className="imgUp-main">
                <div className="style-upload-box">
                    <input
                        className="custom-file-input"
                        type="file"
                        onChange={(e) => onUploadFile(e)}
                    />
                    <div>
                        {loader.imageLoader ?
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#ff6363" }} />
                            : <>
                                <BsUpload />
                                <h6>"Upload your Image"</h6>
                            </>}
                    </div>
                </div>

                <div className="img-search-scroll">
                    <ul>
                        {imageList.length > 0 ?
                            imageList.map((curElem, index) => {
                                return (
                                    <li key={index}>
                                        <div
                                            className="imgUp-single cursor-pointer"
                                            onClick={() => handleChoose(curElem, index)}
                                            style={{ border: +selected.index === index ? "1px solid #d84444" : "" }}
                                        >
                                            <img
                                                src={curElem.url}
                                                alt={curElem.name}
                                            />
                                            <div className='image-delete-button' onClick={(e) => onDelete(e, curElem.id)}>
                                                <BsFillTrashFill />
                                            </div>
                                            <div className="imgStat"><BsCheckCircle /></div>
                                        </div>
                                    </li>
                                )
                            })
                            : ""}
                    </ul>
                    {loader.fetchLoader ?
                        <div className='text-center mt-3'>
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#ff6363" }} />
                        </div>
                        : ""}
                </div>
            </div>
            <div className="btnTab">
                <button className="create-modal-btn" onClick={handleSave}>{loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /> </> : "Use"}</button>
                <button className="create-modal-btn ml-2" onClick={props.handleClose}>Cancel</button>
            </div>


            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={handleDeleteImage}
                onCancel={handleCancle}
            />
        </>
    )
}

export default UploadImage