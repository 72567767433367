import React, { useState } from 'react'
import { FiUpload } from "react-icons/fi";
import ImagesModal from './Modals/ImagesModal';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeHeader } from '../../Actions/ClientsAction';
import defaultImage from "../../images/default_image.jpg"

let modalType = ""
const HeaderSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (type) => {
        modalType = type
        setShow(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeHeader(name, value))
    }

    const handleAddBanner = (data) => {
        if (modalType === 'header-banner') {
            dispatch(onChangeHeader("bannerImage", data.url))
        } else if (modalType === 'header-logo') {
            dispatch(onChangeHeader("logoImage", data.url))
        }
    }

    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Banner Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Banner Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Banner Heading"
                        type="text"
                        name='heading'
                        value={landingData.headers.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Banner Image</span>
                        <div className="inpLabel">
                            <span onClick={() => handleShow('header-banner')} className="fullLink cursor-pointer"></span>
                            {/* <label htmlFor="bannerImage">Banner Image</label> */}
                            <span className='uploaded-img'><img src={landingData.headers.bannerImage !== "" ? landingData.headers.bannerImage : defaultImage} alt="" /></span>
                            <input
                                className="inpLabel-inp"
                                placeholder=""
                                type="text"
                            />
                            <span className="inpBtn-icon">
                                <FiUpload /> <span className="pl-1">Choose</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Logo Image</span>
                        <div className="inpLabel">
                            <span onClick={() => handleShow('header-logo')} className="fullLink cursor-pointer"></span>
                            <span className='uploaded-img'><img src={landingData.headers.logoImage !== "" ? landingData.headers.logoImage : defaultImage} alt="" /></span>
                            {/* <label htmlFor="logoImage">Logo Image</label> */}
                            <input
                                className="inpLabel-inp"
                                placeholder=""
                                type="text"
                            />
                            <span className="inpBtn-icon">
                                <FiUpload /> <span className="pl-1">Choose</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Button Text</span>
                <div className="inpLabel">
                    <label htmlFor="buttonText">Button Text</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Button Text"
                        type="text"
                        name='buttonText'
                        value={landingData.headers.buttonText}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">What Would You Like The Button To Do?</span>
                <div className="inpLabel">
                    <label htmlFor="buttonAction">What Would You Like The Button To Do?</label>
                    <select
                        className="inpLabel-inp"
                        name='buttonAction'
                        value={landingData.headers.buttonAction}
                        onChange={handleChange}
                    >
                        <option value="">Select Button Action</option>
                        <option value="custome-url">Custome URL</option>
                        <option value="scroll-to-contact-us">Scroll To Contact Us</option>
                        <option value="send-an-email">Send An Email</option>
                        <option value="scroll-to-video">Scroll To Video</option>
                    </select>
                </div>
            </div>
            {landingData.headers.buttonAction === "custome-url" ?
                <div className="inpLabelWrap">
                    <span className="labelTxt">Custome Url</span>
                    <div className="inpLabel">
                        <label htmlFor="buttonText">Custome Url</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Custome Url"
                            type="text"
                            name='customeUrl'
                            value={landingData.headers.customeUrl}
                            onChange={handleChange}
                        />
                    </div>
                </div> : ""}

            <div className="inpLabelWrap">
                <span className="labelTxt">Banner Description</span>
                <div className="inpLabel">
                    <label htmlFor="bannerDescription">Banner Description</label>
                    <textarea
                        className="inpLabel-inp"
                        name="bannerDescription"
                        placeholder="Banner Description"
                        value={landingData.headers.bannerDescription}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddBanner}
            />
        </div>

    )
}

export default HeaderSection