import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { onFetchTransition } from '../../../../Actions/VideoAction';
import TransCard from './TransCard';

const Transition = ({ slideData }) => {

    const dispatch = useDispatch()
    const [data, setData] = useState([])

    const fetchTransition = () => {
        dispatch(onFetchTransition(setData))
    }

    useEffect(() => {
        fetchTransition()
    }, [])

    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <h2>Transition</h2>
                    <div className="add-block-main">
                        <div className="motionlist">
                            <ul>
                                {data.length > 0 ?
                                    data.map((curElem, index) => {
                                        if (curElem.image !== "") {
                                            return (
                                                <li key={index} className="col-6" >
                                                    <TransCard
                                                        curElem={curElem}
                                                        slideData={slideData}
                                                    />

                                                </li>
                                            )
                                        }
                                    }) : ''}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transition

