import React, { useEffect, useState } from "react";
import { FiArrowRight, FiSearch } from "react-icons/fi"
import { Link, useLocation } from "react-router-dom";

import queryString from "query-string";
import { onfecthClients } from "../../Actions/ClientsAction";
import { useDispatch, useSelector } from "react-redux";
import ClientTableHeader from "./ClientTableHeader";
import ClientModal from "./ClientModal";
import ClientRowData from "./ClientRowData";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Pagination from "../Agency/Pagination";
import { onGetAgencyData } from "../../Actions/AgencyAction";

const Clients = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const aId = queryString.parse(location.search).id
    const agency = useSelector(state => state.agency.data)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loader, setLoader] = useState(false);
    const [clients, setClients] = useState([])
    const [clientData, setClientData] = useState([])
    const [filter, setFilter] = useState({
        search: "",
        mode: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });


    const fetchClients = () => {
        let data = {
            agencyId: aId
        }
        setLoader(true)
        dispatch(onfecthClients(data, setClients, setLoader))
    }
    const fetchAllClients = () => {
        setLoader(true)
        let data = {
            agencyId: ""
        }
        dispatch(onfecthClients(data, setClients, setLoader))
    }

    const handleSearch = (e) => {
        const { value } = e.target;
        if (value === "") {
            setFilter({ ...filter, mode: "" })
        } else if (value === "1") {
            setFilter({ ...filter, mode: "1" })
        } else if (value === "0") {
            setFilter({ ...filter, mode: "0" })
        }
    }

    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const currentTodo =
        (filter.search === "" && filter.mode !== "") ||
            (filter.search !== "" && filter.mode === "") ||
            (filter.search !== "" && filter.mode !== "")
            ? clientData
            : clientData.slice(indexofFirstTodo, indexofLastTodo);

    useEffect(() => {
        if (filter.search === "" && filter.mode === "") {
            setClientData(clients)
        } else {
            let val = clients
                .filter((curElem) => {
                    if (filter.mode === "") {
                        return curElem;
                    } else {
                        let data = curElem.mode === filter.mode
                        return data;
                    }
                })
                .filter((curElem) => {
                    let data = curElem.name.toLowerCase().includes(filter.search.toLowerCase());
                    if (data) {
                        return curElem;
                    }
                })
            setClientData(val)
        }
    }, [filter, clients])

    const fetchAgencyData = () => {
        let data = {
            id: aId
        }
        dispatch(onGetAgencyData(data, false, false))
    }

    useEffect(() => {
        if (aId) {
            fetchClients()
            fetchAgencyData()
        }
    }, [aId])
    useEffect(() => {
        if (!aId) {
            fetchAllClients()
        }
    }, [])

    return (
        <>
            {aId ? "" :
                <>
                    <TitleBar title="Client" />
                    <Navbar />
                </>
            }

            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap" >
                        {aId ?
                            <div className="productNav nav mb-4" style={{ marginTop: "-70px" }}>
                                <div className="nav-item"><Link className="nav-link" to="/agency"><i class="fa-sharp fa-solid fa-building me-2"></i>All Agencies</Link></div>
                                <div className="nav-item"><Link className="nav-link" to={`/agency/create-agency?id=${aId}`}><i class="fa-regular fa-building me-2"></i>{agency.name}</Link></div>
                                <div className="nav-item"><Link className="nav-link active" to={`/agency/clients?id=${aId}`}><i class="fa-solid fa-users me-2"></i>All Clients</Link></div>
                            </div>
                            : ""
                        }

                        <div className="business-top">
                            <h2>Your Clients</h2>
                            {aId ?
                                <span className="demoLink mt-0" onClick={handleShow}>Create New Client <FiArrowRight /> </span> : ""}
                        </div>

                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><FiSearch /></span>
                                    <input className="inpSearch withIcon"
                                        type="text"
                                        value={filter.search}
                                        onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                        placeholder="Search Client" />
                                </div>
                                <div className="business-search-inp ml-2">
                                    <select name="" id="" onChange={handleSearch} className="inpSearch">
                                        <option value="">All</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>

                            <div className="bListing">
                                <table className="table businessTable">
                                    <thead>
                                        <ClientTableHeader listData={clientData} setListData={setClientData} />
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ClientRowData
                                                            curElem={curElem}
                                                            fetchClients={aId ? fetchClients : fetchAllClients}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }) : ""}
                                    </tbody>
                                </table>


                                <div className="text-center mt-1">
                                    {clients.length === 0 ?
                                        loader ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#d03e3e" }} />
                                            :
                                            `"No client created yet for this agency!"` :
                                        ""}
                                </div>
                                <Pagination
                                    listData={clientData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    currentTodo={currentTodo}
                                    listArr={clients}
                                    loader={loader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {aId ? "" :
                <Footer />
            }
            <ClientModal
                show={show}
                handleClose={handleClose}
                aId={aId}
            />

        </>
    )
}

export default Clients;