import './App.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Components/Auth/Login';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import MasterLogin from './Components/Auth/MasterLogin';
import { PrivateRoute } from './Components/PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/User/Profile';
import Privacy from './Components/User/Privacy';
import AccountManagement from './Components/User/AccountManagement';
import Upgrades from './Components/User/Upgrades';
import HelpSupport from './Components/Support/HelpSupport';
import Training from './Components/Support/Training';
import TrainingArticles from './Components/Support/TrainingArticles';
import Integration from './Components/Integration/Integration';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Actions/AuthAction';
import { removeAlert } from './Actions/AlertAction';
import Alert from './Components/Alert';
import Agency from './Components/Agency/Agency';
import CreateAgency from './Components/Agency/CreateAgency';
import Clients from './Components/Clients/Clients';
import CreateClients from './Components/Clients/CreateClients';
import CreateLandingPage from './Components/LandingPage/CreateLandingPage';
import EditLandingPage from './Components/LandingPage/EditLandingPage';
import CreateBlog from './Components/Blog/CreateBlog';
import AgencyList from './Components/Agency/AgencyList';
import CreateBusinessCard from './Components/BusinessCard/CreateBusinessCard';
import ImageEditor from './Components/ImageEditor/ImageEditor';
import Topic from './Components/VideoEditor.jsx/Topic';
import VideoScript from './Components/VideoEditor.jsx/VideoScript';
import Editor from './Components/VideoEditor.jsx/editor/Editor';
import FacebookPage from './Components/FacebookPage/FacebookPage';
import GooglePage from './Components/FacebookPage/GooglePage';
import CreateLetterHead from './Components/LetterHeads/CreateLetterHead';
import CreateInvoice from './Components/Invoices/CreateInvoice';
import CreateEnvelope from './Components/Envelopes/CreateEnvelope';
import InvoiceEditor from './Components/Invoices/InvoiceEditor'
import CreatePress from './Components/Press/CreatePress';
import CreateImagePage from './Components/ImageCard/CreateImage';
import CreateLeadMagnet from './Components/LeadMagnet/CreateLeadMagnet';
import CreateSocialMediaCover from './Components/SocialMediaCover/CreateSocialMediaCover';
import Staff from './Components/Staff/Staff';
import Conversations from './Components/Conversations/Conversations';
import Chat from './Components/Chat/Chat';
function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <div className="App">
      <Alert />
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/master-login' element={<MasterLogin />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/help-and-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /></PrivateRoute>} />

            <Route exact path="/agency" element={<PrivateRoute><Agency /></PrivateRoute>} >
              <Route index element={<AgencyList />} />
              <Route path="create-agency" element={<PrivateRoute><CreateAgency /></PrivateRoute>} />
              <Route path="clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
              <Route path="create-client" element={<PrivateRoute><CreateClients /></PrivateRoute>} />
              <Route path="integration" element={<PrivateRoute><Integration /></PrivateRoute>} />
              <Route path="create-landing-page" element={<PrivateRoute><CreateLandingPage /></PrivateRoute>} />
              <Route path="edit-landing-page" element={<PrivateRoute><EditLandingPage /></PrivateRoute>} />
              <Route path="create-business-card" element={<PrivateRoute><CreateBusinessCard /></PrivateRoute>} />
              <Route path="create-letter-head" element={<PrivateRoute><CreateLetterHead /></PrivateRoute>} />
              <Route path="create-invoice" element={<PrivateRoute><CreateInvoice /></PrivateRoute>} />
              <Route path="invoice-editor" element={<PrivateRoute><InvoiceEditor /></PrivateRoute>} />
              <Route path="create-envelope" element={<PrivateRoute><CreateEnvelope /></PrivateRoute>} />
              <Route path="create-image-page" element={<PrivateRoute><CreateImagePage /></PrivateRoute>} />
              <Route path="create-lead-magnet" element={<PrivateRoute><CreateLeadMagnet /></PrivateRoute>} />
              <Route path="create-social-media" element={<PrivateRoute><CreateSocialMediaCover /></PrivateRoute>} />
              <Route path="image-editor" element={<PrivateRoute><ImageEditor /></PrivateRoute>} />
              <Route path="create-topic" element={<PrivateRoute><Topic /></PrivateRoute>} />
              <Route path="video-script" element={<PrivateRoute><VideoScript /></PrivateRoute>} />
              <Route path="editor" element={<PrivateRoute><Editor /></PrivateRoute>} />
              <Route path="create-blog" element={<PrivateRoute><CreateBlog /></PrivateRoute>} />
              <Route path="create-press" element={<PrivateRoute><CreatePress /></PrivateRoute>} />
              <Route path="create-facebook" element={<PrivateRoute><FacebookPage /></PrivateRoute>} />
              <Route path="create-google" element={<PrivateRoute><GooglePage /></PrivateRoute>} />
              <Route path="create-social-media" element={<PrivateRoute><CreateSocialMediaCover /></PrivateRoute>} />

            </Route>
            <Route exact path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />

            <Route exact path="/staff" element={<PrivateRoute><Staff /></PrivateRoute>} />
            <Route exact path="/conversations" element={<PrivateRoute><Conversations /></PrivateRoute>} />
            <Route exact path="/chat" element={<PrivateRoute><Chat /></PrivateRoute>} />

            {/* <Route exact path="/create-facebook" element={<PrivateRoute><FacebookPage /></PrivateRoute>} />
            <Route exact path="/create-google" element={<PrivateRoute><GooglePage /></PrivateRoute>} /> */}
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
