import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai"
import { SlCheck, SlClose } from "react-icons/sl"
import { onFetchConversationData, onFetchStaffData, fetchTeamData } from "../../Actions/StaffAction";
import { useDispatch } from "react-redux";
import ConversationCard from "./ConversationCard";

const Conversations = () => {

    const dispatch = useDispatch();

    const [conversation, setConversation] = useState([])
    const [staff, setStaff] = useState([])
    const [member, setMember] = useState([])
    const [loader, setLoader] = useState(false)
    const [filterData, setFilterData] = useState({
        team: "",
        staff: ""
    })

    const conversationData = () => {
        setLoader(true)
        let data = {}
        dispatch(onFetchConversationData(data, setConversation, setLoader))
    }

    const onfetchStaffName = () => {
        setLoader(true)
        let data = {}
        dispatch(onFetchStaffData(data, setStaff, setLoader))
    }

    const onfetchTeamMember = () => {
        dispatch(fetchTeamData(setMember))
    }

    const handleSelectStaff = (e) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value
        })
    };

    useEffect(() => {
        conversationData()
        onfetchStaffName()
        onfetchTeamMember()
    }, [])

    return (
        <>
            <TitleBar title="Staff" />
            <Navbar />
            <section className="siteWrap">
                <section className="convSec convSec-page">
                    <div className="container">
                        <div className="convSec-wrap">

                            <div className="convSec-search">
                                <div className="convSec-search-single">
                                    <label htmlFor="">Team Member</label>
                                    <select name="team" onChange={handleSelectStaff} className="ml-3">
                                        <option value="">Select Team Member</option>
                                        {
                                            member.length > 0 ?
                                                member.map((elem, ind) => {
                                                    return (
                                                        <option key={ind} className="text-capitalize" value={elem.id}>{elem.name}</option>
                                                    )
                                                })
                                                : ""
                                        }
                                    </select>
                                </div>
                                <div className="convSec-search-single ml-5">
                                    <label htmlFor="">Staff</label>
                                    <select
                                        name="staff"
                                        className="ml-3"
                                        onChange={handleSelectStaff}
                                    >
                                        <option value="">Select Staff</option>
                                        {
                                            staff.length > 0 ?
                                                staff.map((elem, ind) => {
                                                    return (
                                                        <option key={ind} className="text-capitalize" value={elem.staffId}>{elem.name}</option>
                                                    )
                                                })
                                                : ""
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                {
                                    conversation.length > 0 ?
                                        conversation.filter((item) => {
                                            if (filterData.staff !== "") {
                                                return +item.staffId === +filterData.staff
                                            }else if (filterData.team !== ""){
                                                return +item.userId === +filterData.team
                                            } 
                                            else {
                                                return item
                                            }
                                        })
                                        .map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ConversationCard
                                                        curElem={curElem}
                                                        index={index}
                                                    />

                                                </React.Fragment>
                                            )
                                        })
                                        : ""
                                }

                                <div className="text-center mt-1 text-light"  >
                                    {conversation.length == 0 ?
                                        loader ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} />
                                            :
                                            `"No conversation created yet!"` :
                                        ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <Footer />
        </>
    )
}

export default Conversations;