import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  onFetch,
  onGetClient,
  onSubmitLanding,
} from "../../Actions/ClientsAction";
import queryString from "query-string";
import Template from "../LandingPage/Template";
import { IoMdArrowRoundBack } from "react-icons/io";
import { setAlert } from "../../Actions/AlertAction";
import { onCreateBusinessCard, onCreateInvoice } from "../../Actions/ResourceAction";

const CreateInvoice = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const aId = queryString.parse(location.search).id;
  const cId = queryString.parse(location.search).cId;
  const clientData = useSelector((state) => state.client.data);

  const [templateList, setTemplateList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [loader, setLoader] = useState({
    pageLoader: true,
    submitLoader: false,
  });

  const [state, setState] = useState({
    state: "",
    templateId: 1,
    agencyId: aId,
    clientId: cId,
    type: "invoice",
  });

  const handleSubmit = () => {
    if (state.templateId !== -1) {
      setLoader({
        ...loader,
        submitLoader: true,
      });
        dispatch(onCreateBusinessCard(state, navigate, loader, setLoader));
        // navigate(`/agency/invoice-editor?aId=${aId}&cId=${cId}&id=${id}` );
    } else {
      dispatch(setAlert("Please enter invoice name!", "danger"));
    }
  };

  const handleBackBtn = () => {
    if (aId && cId) {
      navigate(`/agency/create-client?id=${aId}&cId=${cId}`);
    }
  };

  const getClientData = () => {
    let data = {
      id: cId,
      agencyId: aId,
    };
    setLoader({
      ...loader,
      pageLoader: true,
    });
    dispatch(onGetClient(data, loader, setLoader));
  };

  const getTemplate = () => {
    let ep = "list-image-templates";
    let data = {
      type: "invoice",
    };
    dispatch(onFetch(ep, data, setTemplateList));
  };

  useEffect(() => {
    getTemplate();
  }, []);

  useEffect(() => {
    if (aId && cId) {
      getClientData();
    }
  }, [aId, cId]);

  return loader.pageLoader ? (
    <div className="loader-sec">
      <div className="loader" />
    </div>
  ) : (
    <>
      <div className="container">
        <div className="business-wrap">
          <div className="business-top">
            <h2>Create Invoice</h2>
            <button className="demoLink" onClick={handleBackBtn}>
              {" "}
              <IoMdArrowRoundBack /> Go Back
            </button>
          </div>

          <div className="business-list">
            <div className="proImage withDesc">
              <div className="proImage-holder">
                <img src={clientData.image} alt="" />
              </div>
              <div className="proImage-txt">
                <p>
                  <strong>{clientData.name}</strong>
                </p>
                <p>{clientData.email}</p>
                <p>{clientData.company}</p>
              </div>
            </div>
          </div>

          <div className="business-list">
            <div className="inpLabelWrap ">
              <span className="labelTxt">Invoice Name</span>
              <div className="inpLabel">
                <label>Invoice Name</label>
                <input
                  className="inpLabel-inp"
                  placeholder="Enter Name"
                  type="text"
                  value={state.name}
                  name="name"
                  onChange={(e) =>
                    setState({
                      ...state,
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="business-search justify-content-end mt-4">
              <div className="business-search-inp">
                <span className="searchIcon">
                  <FiSearch />
                </span>
                <input
                  className="inpSearch withIcon"
                  type="text"
                  placeholder="Search Template"
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
            </div>

            <div className="list-template">
              <div className="row">
                {templateList.length > 0
                  ? templateList
                      .filter((temp) => {
                        return temp.name
                          .toLowerCase()
                          .includes(searchKey.toLowerCase());
                      })
                      .map((curElem, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Template
                              curElem={curElem}
                              setState={setState}
                              state={state}
                              thumbnail={curElem.previewUrl}
                            />
                          </React.Fragment>
                        );
                      })
                  : ""}
              </div>
            </div>

            <div className="btn-sec text-right">
              <span onClick={handleSubmit} className="demoLink">
                {loader.submitLoader ? (
                  <>
                    {" "}
                    Next <i className="fa fa-spinner fa-spin mx-1" />{" "}
                  </>
                ) : (
                  "Next"
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
