import React, { useState, useEffect } from 'react';
import { Tab, Nav, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import closeModalx from "../../../../images/modal-close.png"
import HoverVideoPlayer from "react-hover-video-player";
import sample from "../../../../images/sample.png";
import { setOutroStatus, removeOutro, updateIntroOutro, onDeleteMediaIntroOutro, onFileUploadIntroOutro, onFetchUploadFile } from '../../../../Actions/VideoAction';
import Swal from 'sweetalert2';
import SweetAlertTwo from '../../../SweetAlertTwo';


const Outro = () => {

    const dispatch = useDispatch()
    const video = useSelector(state => state.video)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [outroData, setOutroData] = useState(false)
    const [outroArr, setOutroArr] = useState([])

    const [loader, setLoader] = useState({
        outroLoader: false
    });
    const [intOut, setIntOut] = useState({
        id: false,
        enable: false,
        type: 'outro',
    })

    const selectOutro = (curElem) => {
        let data = {
            url: '',
            nameData: '',
            thumbData: ''
        }
        data.url = curElem.url
        data.nameData = curElem.name
        data.thumbData = curElem.poster
        handleClose()
        dispatch(updateIntroOutro('UPDATE_OUTRO', data))
    }

    const onConfirm = (Swal) => {
        let formData = new FormData();
        formData.append('id', intOut.id);
        dispatch(onDeleteMediaIntroOutro(formData, fetchOutro, Swal))
        setIntOut({
            ...intOut,
            id: false,
            enable: false,
            type: "outro"
        })
    }

    const handleCancle = () => {
        setIntOut({
            ...intOut,
            id: false,
            enable: false,
            type: "outro"
        })
    }

    const openDeletePopUp = (e, id) => {
        e.stopPropagation()
        setIntOut({
            ...intOut,
            id: id,
            enable: true,
            type: "outro"
        })
    }

    useEffect(() => {
        if (video.outro) {
            setOutroData(video.outro)
        }
    }, [video.outro])

    const fetchOutro = () => {
        const data = {
            type: "outro",
            agencyId: video.agencyId,
            clientId: video.clientId
        }
        dispatch(onFetchUploadFile(data, setOutroArr))
    }

    const handleLoader = (status) => {
        setLoader({
            ...loader,
            outroLoader: status
        });
    }

    const onInputVideo = (e, type) => {
        if (e.target.files[0].type === "video/mp4") {
            const allowedSize = 20000000;
            if (e.target.files[0].size < allowedSize) {
                handleLoader(true, type)
                const formData = new FormData();
                formData.append('file', e.target.files[0])
                formData.append('upload_type', type)
                formData.append('agencyId', video.agencyId)
                formData.append('clientId', video.clientId)
                dispatch(onFileUploadIntroOutro(formData, type, handleIntroOutro, handleLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size for video is 20MB!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "You have Selected Invalid File Type!",
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const handleStatusIntroOutro = (e) => {
        dispatch(setOutroStatus(e.target.checked))
        if (e.target.checked === false) {
            dispatch(removeOutro())
        }
    }

    const handleIntroOutro = (dataVal) => {
        fetchOutro()
        let data = {
            url: dataVal.path,
            nameData: dataVal.name,
            thumbData: dataVal.thumbnail
        }
        dispatch(updateIntroOutro('UPDATE_OUTRO', data))
    }

    useEffect(() => {
        fetchOutro()
    }, []);

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title mb-4">
                    <h2>Outro</h2>
                    <div className="switch-single">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={outroData.enable}
                                onChange={(e) => handleStatusIntroOutro(e, 'outro')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {outroData.enable ?
                    <>
                        <div className="fileUpload" >
                            <i className="fa fa-cloud-upload" aria-hidden="true"></i>

                            {outroData.name ?
                                outroData.name.length > 23 ? `${outroData.name.slice(0, 23)}...` : outroData.name
                                : 'Upload Your Outro'}
                            <input
                                type="file"
                                className='cursor-pointer'
                                onChange={(e) => onInputVideo(e, 'outro')}
                            />
                        </div>
                        <div className="itemDisp">
                            <div className="itemDisp-left text-center">
                                <ul>
                                    <li style={{ position: 'relative' }}>
                                        {
                                            loader.outroLoader ? <i className="fa fa-spinner fa-spin loader-center" />
                                                : <img
                                                    src={outroData.thumbnail ? outroData.thumbnail : sample}
                                                    alt=""
                                                    className="uploadImg"
                                                />
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className='itemDisp-right'>
                                <button onClick={handleShow}>Select Outro Video +</button>
                            </div>
                            <Modal show={show} onHide={handleClose}>
                                <button className='model-close-button text-right' onClick={handleClose}>
                                    <img src={closeModalx} alt="" />
                                </button>
                                <Modal.Body style={{ color: '#ffffff' }}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="tabInner">
                                                <Tab.Container id="" defaultActiveKey="intro-video">
                                                    <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded outro videos</Nav.Link>
                                                        </Nav.Item>

                                                    </Nav>

                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="intro-video">
                                                            <div className="innertab credit-modal" style={{ paddingRight: '20px' }}>
                                                                <div className="row gy-4">
                                                                    {outroArr.length > 0 ?
                                                                        outroArr.map((curElem) => {
                                                                            return (
                                                                                <div
                                                                                    className="col-4 pe-2 ps-1"
                                                                                    key={curElem.id}
                                                                                    onClick={() => selectOutro(curElem)}
                                                                                >
                                                                                    <div className="credit-video-card" style={{
                                                                                        border: "none",
                                                                                    }}>
                                                                                        <div className="">
                                                                                            <>
                                                                                                <HoverVideoPlayer
                                                                                                    className="my-1 cursor-pointer "
                                                                                                    videoSrc={curElem.url}
                                                                                                    pausedOverlay={
                                                                                                        <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                                                    }
                                                                                                    loadingOverlay={
                                                                                                        <div><i className="fa fa-spinner fa-spin hover-loader-center" style={{ top: '46%' }} /></div>
                                                                                                    }
                                                                                                />
                                                                                                <FiTrash2
                                                                                                    className='remove-media'
                                                                                                    onClick={(e) => openDeletePopUp(e, curElem.id, 'intro')}
                                                                                                    color="#ff6363"
                                                                                                />
                                                                                            </>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className='text-center' style={{ fontSize: 20 }}>
                                                                            You don't have uploaded Outro videos
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <SweetAlertTwo
                                    show={intOut.enable}
                                    title="Are You Sure?"
                                    text="You won't be able to revert this!"
                                    icon="warning"
                                    showCancelButton={true}
                                    confirmButtonColor='#3085d6'
                                    cancelButtonColor='#d33'
                                    confirmButtonText='Yes, delete it!'
                                    confirmTitle='Deleting...'
                                    confirmText="This won't be revert!"
                                    confirmIcon='success'
                                    handlePerform={onConfirm}
                                    onCancel={handleCancle}
                                />
                            </Modal>
                        </div>
                    </> : ''}
            </div>

        </div>
    );
}

export default Outro;
