import React, { useState } from 'react'
import { SlCheck, SlClose } from "react-icons/sl"
import SweetAlertTwo from '../SweetAlertTwo';
import { onChangeStatus } from '../../Actions/StaffAction';
import { useDispatch } from 'react-redux';

const StaffCard = ({ curElem,fetchStaffData }) => {

    const dispatch = useDispatch();

    const [info, setInfo] = useState({
        id: -1,
        enable: false,
        text: "",
        title: "",
        confirm:""
    })

    const handleBtn = (id, type) => {
        if (type === "close") {
            setInfo({
                ...info,
                id: id,
                enable: true,
                text: "You want to fired this staff",
                title: "firing...",
                confirm:'Yes, fired it!',
                ep:"fire-staff"
            })
        } else {
            setInfo({
                ...info,
                id: id,
                enable: true,
                text: "You want to hired this staff",
                title: "hiring...",
                confirm:'Yes, hired it!',
                ep:"hire-staff"
            })
        }
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const deleteAgencyData = (swal) => {
        let data = {
            staffId: info.id
        }
        dispatch(onChangeStatus(data,info.ep,swal,fetchStaffData))
        setInfo({
            ...info,
            id: -1,
            enable: false,
            text: "",
            title: "",
            confirm:""
        })
    }

    return (
        <>
            <div className="col-sm-6 col-lg-3">
                <div className="staff-single">
                    <div className="staff-img"><img src={curElem.image} alt="" /></div>
                    <div className="staff-txt">
                        <h6>{curElem.name} <br /> <span>{curElem.category}</span> </h6>
                        <p>{curElem.description}</p>
                        <div className="staff-btn mt-3">
                            {curElem.isfired === "1" ?
                                <button className="red" onClick={() => handleBtn(curElem.staffId, 'close')}><SlClose /></button> :
                                <button className="green" onClick={() => handleBtn(curElem.staffId, 'check')}><SlCheck /></button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text={info.text}
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText={info.confirm}
                confirmTitle={info.title}
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAgencyData}
                onCancel={handleCancel}
            />
        </>
    )
}

export default StaffCard