import React from 'react'
import { Link } from 'react-router-dom'

const ConversationCard = ({ curElem, index }) => {
    return (

        <div className="col-sm-6 col-lg-4">
            <div className="convSec-single">
                <div className="convSec-img"><img src={curElem.image} alt="" /></div>
                <div className="convSec-txt">
                    <h6>{curElem.name}</h6>
                    <div className="staff-btn">
                        <Link to="" className="demoLink mt-2" href="">View Conversations</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConversationCard