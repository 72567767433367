import React, { useState, useEffect } from "react";
import iconSave from "../../../images/icon-save.svg";
import iconrender from "../../../images/icon-render.svg";
import iconStory from "../../../images/icon-story.svg";
import iconText from "../../../images/icon-text.svg";
import iconMedia from "../../../images/icon-media.svg";
import iconAudio from "../../../images/icon-audio.svg";
import iconSettings from "../../../images/icon-settings.svg";
import iconCredits from "../../../images/icon-credits.svg";
import iconTransition from "../../../images/icon-transition.svg";
import { Tab, Nav } from "react-bootstrap";
import Audio from "./audio/Audio"
import Preview from "./preview/Preview";
import VideoTiles from "./videoTiles/videoTiles";
import Story from "./story/Story";
import Text from "./text/Text";
import Media from "./media/Media";
import BackgroundMedia from "./backgroundMedia/BackgroundMedia";
import Setting from "./setting/Setting";
import Credits from "./credits/Credits";
import Transition from "./transition/Transition";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import VoiceOver from "./voiceOver/VoiceOver";
import { addSlideData, removeSlides, addSlides, onFetchVideo, onSaveAndRenderSlides, onSaveVideo, removeActionType } from "../../../Actions/VideoAction";
import { setAlert } from "../../../Actions/AlertAction";
import { commonAxios } from "../../../Global/CommonAxios";



const Editor = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const videoId = queryString.parse(location.search).id

  const [loader, setLoader] = useState(true)

  const video = useSelector(state => state.video)
  const auth = useSelector(state => state.auth)

  const [loadRender, setLoadRender] = useState(false);
  const [memberShip, setMemberShip] = useState([])

  useEffect(() => {
    if (auth.user) {
      let data = auth.user.membership
      setMemberShip(data.split("__"))
    }
  }, [auth.user])





  const saveAndRender = () => {
    let data = {
      videoId: video.videoId,
      type: "render"
    }
    setLoadRender(true)
    dispatch(onSaveAndRenderSlides(data, setLoadRender, navigate, video.agencyId, video.clientId))

  }


  const handleProject = () => {
    let data = {
      id: video.videoId
    }
    let totalTime = 0
    video.slides.forEach((curElem) => {
      totalTime += parseInt(curElem.duration)
    })
    totalTime = Math.floor(totalTime / 60)

    if (memberShip[0] === "premium" && memberShip.length === 1) {
      if (totalTime <= 15) {
        saveAndRender(data)
      }
      else {
        dispatch(setAlert("This campaign can not be rendered because the max limit of the rendered video is 15 min.", "danger"))
      }
    } else {
      if (totalTime <= 30) {
        saveAndRender(data)
      }
      else {
        dispatch(setAlert("This campaign can not be rendered because the max limit of the rendered video is 30 min.", "danger"))

      }
    }
  }



  const fetchVideo = () => {
    let data = { videoId }
    dispatch(onFetchVideo(data, setLoader))
  }

  useEffect(() => {
    if (video) {
      dispatch(onSaveVideo(video))
      if (video.actionType === "DELETE_TILE") {
        fetchVideo()
        dispatch(removeActionType())
      }
    }
  }, [video])

  useEffect(() => {
    if (videoId) {
      fetchVideo()
    }
  }, [videoId])

  return (

    loader ?
      <div className="loader-sec">
        <div className="loader">
        </div>
      </div> :
      <>

        <section className="siteWraphh">
          <div className="editorWrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey="story">
              <div className="editor-left">
                <div>
                  <Tab.Content>

                    <Tab.Pane eventKey="story">
                      <Story
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="text">
                      <Text
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="bgMedia">
                      <BackgroundMedia
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="media">
                      <Media
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="voiceOver">
                      <VoiceOver
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="audio">
                      <Audio
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="settings">
                      <Setting
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="credits">
                      <Credits />
                    </Tab.Pane>

                    <Tab.Pane eventKey="transition">
                      <Transition
                        slideData={video.slides}
                      />
                    </Tab.Pane>

                  </Tab.Content>
                </div>
              </div>

              <div className="editor-right">
                <div className="campaignTop">
                  <div className="campaignBar">
                    <button
                      className="demoLink cursor-poninter"
                      onClick={() => handleProject()}
                    >
                      {loadRender ?
                        <>
                          Rendering
                          <i className="fa fa-spinner fa-spin mx-1" />

                        </> :
                        <>
                          Render
                          <img src={iconrender} alt='render-icon' className="mx-1" />

                        </>
                      }
                    </button>
                  </div>
                  <div className="campaignNav">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="story">
                          <div>
                            <span><img src={iconStory} /></span>
                            <span>Story</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="text">
                          <div>
                            <span><img src={iconText} /></span>
                            <span>Text</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="bgMedia">
                          <div>
                            <span><img src={iconMedia} /></span>
                            <span>Background</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="media">
                          <div>
                            <span><img src={iconMedia} /></span>
                            <span>Media</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="voiceOver">
                          <div>
                            <span><img src={iconAudio} /></span>
                            <span>Voiceover</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="audio">
                          <div>
                            <span><img src={iconAudio} /></span>
                            <span>Music</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="transition">
                          <div>
                            <span><img src={iconTransition} /></span>
                            <span>Transition</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="settings">
                          <div>
                            <span><img src={iconSettings} /></span>
                            <span>Settings</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="credits">
                          <div>
                            <span><img src={iconCredits} /></span>
                            <span>Credits</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>


                <Preview
                  bgData={video.audio}
                  slideData={video.slides}
                  logo={video.logo}
                  watermark={video.watermark}
                />
              </div>
            </Tab.Container>
          </div>
        </section>

        <div className="editorReel">
          <VideoTiles
            slideData={video.slides}
          />
        </div>
      </>
  )
}

export default Editor;