import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io"
import { useDispatch } from "react-redux";
import { onCreateBlog, onSaveBlog, fetchBlog } from "../../Actions/ResourceAction";
import queryString from "query-string";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { onGetClient } from "../../Actions/ClientsAction";

const CreateBlog = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const campId = queryString.parse(location.search).id
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)
    const [blog, setBlog] = useState({
        title: "",
        article: "",
        aiPresent: false
    })

    const [loader, setLoader] = useState(false)
    const [saveLoader, setSaveLoader] = useState(false)
    const [keyword, setKeword] = useState("")
    const [status, setStatus] = useState(false)

    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        } else {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            keyword: keyword,
            id: campId,
        }
        dispatch(onCreateBlog(data, setStatus, setLoader))
    }

    const handleSave = () => {
        setSaveLoader(true)
        delete blog.aiPresent
        let data = {
            id: campId,
            aiContent: blog,
        }
        dispatch(onSaveBlog(data, setSaveLoader, navigate, aId, cId))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setBlog({
            ...blog,
            [name]: value
        })
    }

    const viewBlog = () => {
        let data = {
            id: campId,
        }
        dispatch(fetchBlog(data, setBlog, setLoader))
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])

    useEffect(() => {
        if (campId) {
            viewBlog()
        }
    }, [campId, status])


    return (
        <>
            <div className="container">
                <div className="business-wrap">
                    <div className="business-top">
                        <h2>Create Blog Article</h2>
                        <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                    </div>
                    <div className="business-list">
                        <div className="proImage withDesc">
                            <div className="proImage-holder">
                                <img src={clientData.image} alt="" />
                            </div>
                            <div className="proImage-txt">
                                <p><strong>{clientData.name}</strong></p>
                                <p>{clientData.email}</p>
                                <p>{clientData.company}</p>
                            </div>
                        </div>
                    </div>
                    <div className="business-list">
                        <form className="row" onSubmit={handleClick}>
                            <div className="col-lg-6">
                                <div className="inpLabelWrap mt-0">
                                    <span className="labelTxt">Keyword</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Keyword</label>
                                        <input className="inpLabel-inp"
                                            placeholder="Enter keyword"
                                            type="text"
                                            value={keyword}
                                            onChange={(e) => setKeword(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="btn-sec text-right col-lg-6 ">
                                <button className="demoLink" type="submit">
                                    {loader ? <>Creating Blog Article <i className="fa fa-spinner fa-spin mx-1" /></> : "Create Blog Article"}
                                </button>
                            </div>
                            <div className="alert alert-info mt-4">
                                <strong>NOTE:</strong> Please note that generating AI content would take around 1-2 minutes. So please wait and let the process complete.
                            </div>
                        </form>
                        
                        {blog.aiPresent !== "" ?
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Blog Title</span>
                                <div className="inpLabel">
                                    <label htmlFor="">Below is the generated Blog Title</label>
                                    <input
                                        className="inpLabel-inp"
                                        name="title"
                                        onChange={handleChange}
                                        value={blog.title}
                                        placeholder="Update Title"
                                    />
                                </div>
                                <span className="labelTxt">Article</span>
                                <div className="inpLabel">
                                    <label
                                        htmlFor=""
                                        style={{
                                            width: "100%",
                                            paddingBottom: "10px",
                                            borderTopLeftRadius: "10px",
                                            borderTopRightRadius: "10px",
                                            background: "#fff",
                                            borderTop: "1px solid #ffbebe",
                                            borderLeft: "1px solid #ffbebe",
                                            borderRight: "1px solid #ffbebe"
                                        }}
                                    >
                                        Article
                                    </label>
                                    <textarea
                                        className="inpLabel-inp blog_textarea"
                                        name="article"
                                        onChange={handleChange}
                                        value={blog.article}
                                        placeholder="Add Article"
                                    />
                                </div>
                                <div className="btn-sec text-right">
                                    <button className="demoLink" onClick={handleSave}>
                                        {saveLoader ? <> Saving Article <i className="fa fa-spinner fa-spin mx-1" /></> : `Save Article`}
                                    </button>
                                </div>
                            </div> : ""}
                    </div>

                </div>
            </div>


        </>
    )
}

export default CreateBlog;