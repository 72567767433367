import axios from "axios"
import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"
import WebFont from "webfontloader";


export const onFetchVideo = (data, setLoader) => (dispatch, getState) => {
    commonAxios("view-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_SLIDE_CHAPTER", payload: res.data })
            } else {
                dispatch(res.msg, "danger")
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onLoadMedia = (data, imageData, setImageData, search, setSearch, page, setError, setLoader) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...imageData];
                if (page === 1) {
                    arr = []
                }
                setImageData(arr.concat(res.data));

            } else {
                setError(`Oops, No data found for the Keyword "${search.keyword}"`);
            }
            setLoader(false);
            setSearch({ ...search, page: page });
        }).catch(err => {
            setLoader(false);
            console.log(err)
        })
}

export const onGenerateFile = (data, setOutputImage, setCropLoader) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputImage(res.data);
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setCropLoader(false);
        }).catch(err => {
            console.log(err)
            setCropLoader(false);
        })
}

export const onVideoCrop = (formData, setOutputVideo, setOutputThumb, setCropLoader) => (dispatch, getState) => {
    commonAxios("video-crop", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setCropLoader(false);
        }).catch((err) => {
            console.log(err)
            setCropLoader(false);
        })
}

export const onFetchFiles = (data, type, setUploadImages, setUploadVideos, setDataFiles) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let val = res.data
                // val = val.reverse()
                if (type === "images") {
                    setUploadImages(val)
                }
                else if (type === "video") {
                    setUploadVideos(val)
                }
                else {
                    setDataFiles(val)
                }
            }
            else {
                setUploadImages([])
                setUploadVideos([])
                setDataFiles([])
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onUploadFileVideo = (data, fetchFiles, type, setPercent, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchFiles(type)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader(false)
        }).catch(() => {
            setPercent(0)
            setLoader(false)
        })
}

export const onGenerateTts = (data, setTtsStatus, generateData, setGenerateData, setLoader) => (dispatch, getState) => {
    commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTtsStatus(false)
                setGenerateData({
                    ...generateData,
                    url: res.data.file_url,
                    duration: res.data.duration - 3
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
                setTtsStatus(true)
            }
            setLoader(false);
        }).catch((error) => {
            setTtsStatus(true)
            setLoader(false);
            console.log(error)
        })
}

export const onGetPolly = (pollyLanguages, setPollyLanguages, memberShip) => (dispatch, getState) => {
    commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data
                if (memberShip[0] === "premium" && memberShip.length === 1) {
                    arr = res.data.filter((curElem) => {
                        return (curElem.name.includes("English") || curElem.name.includes("French") || curElem.name.includes("Hindi") || curElem.name.includes("Spanish"))
                    })
                }
                setPollyLanguages({ ...pollyLanguages, data: arr });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGetArtist = (formData, pollyVoices, setPollyVoices) => (dispatch, getState) => {
    commonAxios("get-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setPollyVoices({ ...pollyVoices, data: res.data });
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onDeleteMediaVideo = (formData, fetchRecording, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchRecording();
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            Swal.close()
        }).catch((err) => {
            dispatch(setAlert(err.msg, 'danger'));
            Swal.close()
        })
}

export const onDeleteMediaAudio = (formData, type, fetchFiles, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchFiles(type)
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            if (Swal) {
                Swal.close()
            }
        }).catch((error) => {
            console.log(error)
            if (Swal) {
                Swal.close()
            }
            dispatch(setAlert(error.msg, 'danger'));
        })
}

export const onDeleteMedia = (formData, mediaType, fetchFiles, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                mediaType === "image" ?
                    fetchFiles("images") :
                    fetchFiles("video")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            Swal.close()
        })
}

export const onFileUploadRecording = (formData, loader, setLoader, fetchRecording) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchRecording()
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
            }
            else {
                setLoader({
                    ...loader,
                    uploadLoader: false
                })
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
}

export const onFetchRecording = (data, recordingData, setRecordingData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status === true) {
                let val = res.data
                val = val.reverse()
                setRecordingData(val);
            }
            else {
                if (recordingData.length === 1) {
                    setRecordingData([])
                }
            }
            setLoader({
                ...loader,
                fetchLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetchLoader: false
            })
        })
}

export const onFetchGoogleLanguage = (setTransLang) => (dispatch, getState) => {
    commonAxios("fetch-google-language", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTransLang(res.data);
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onTranslate = (data, translate, setTranslate, selectedSlideIndex, setLoader) => (dispatch, getState) => {
    commonAxios("translate", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTranslate({
                    ...translate,
                    transText: res.data.text,
                })
                dispatch(updateTransLateText(res.data.text, selectedSlideIndex))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onFetchAudio = (data, audioData, setAudioData, setPageCount, page, setError, setLoader) => (dispatch, getState) => {
    commonAxios("load-library-audio", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setAudioData(audioData.concat(res.data));
                setPageCount(page);
            } else {
                setError(res.msg);
            }
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            console.log(error)
        })
}

export const onFetchTransition = (setData) => (dispatch, getState) => {
    commonAxios("fetch-transition", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onGetPos = (setPosList) => (dispatch, getState) => {
    commonAxios("position-list", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setPosList(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onUploadLogoWatermark = (formData, selectImage, name, setName, setLoadLogo, setLoadWatermark, type) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                selectImage(res.data.path, type)
                res.data.type === "logo" ?
                    setName({
                        ...name,
                        logoName: res.data.name
                    }) :
                    setName({
                        ...name,
                        waterMarkName: res.data.name
                    })

            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }

            setLoadLogo(false)
            setLoadWatermark(false)
        }).catch(() => {
            setLoadLogo(false);
            setLoadWatermark(false);
        })
}

export const onDeleteMediaIntroOutro = (formData, fetchIntro, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchIntro()
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            Swal.close()
        }).catch((error) => {
            Swal.close()
            dispatch(setAlert(error.msg, 'danger'));
            console.log(error)
        })
}

export const onFetchUploadFile = (video, setIntroArr) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", video, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let val = res.data
                val = val.reverse()
                setIntroArr(val)
            } else {
                if (res.data.length === 0) {
                    setIntroArr(res.data)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onFileUploadIntroOutro = (formData, type, handleIntroOutro, handleLoader) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "intro") {
                    handleIntroOutro(res.data, "intro")
                }
                else {
                    handleIntroOutro(res.data, "outro")
                }
                handleLoader(false, type)
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
                handleLoader(false, type)
            }
        }).catch((err) => {
            handleLoader(false, type)
        })
}

export const onSaveAndRenderSlides = (data, setLoadRender, navigate, aId, cId) => (dispatch, getState) => {
    commonAxios("save-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'))
                navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
            }
            setLoadRender(false)
        }).catch((error) => {
            setLoadRender(false)
            console.log(error)
        })
}

export const onSaveVideo = (data) => (dispatch, getState) => {
    let obj = { ...data }
    obj.type = "saved"
    commonAxios("save-video", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((error) => {
            console.log(error)
        })
}

export const onAddSlide = (data, setLoader) => (dispatch, getState) => {
    commonAxios("slide-add", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(addNewSlide(res.data))
                dispatch(setAlert("A new slide added successfully", "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onFetchFont = (setFonts, fontNotSet) => (dispatch) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            WebFont.load({
                google: {
                    families: arr
                }
            });
            setFonts(arr);
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onCheckStatus = (data, setState, checkData, rendCheck, setCheckData, clearInterval, fetchResource) => (dispatch, getState) => {
    commonAxios("check-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data[0].status === "6") {
                    setState(+res.data[0].status)
                    setCheckData({
                        ...checkData,
                        renderProgress: res.data[0].renderProgress,
                        renderStatus: res.data[0].renderStatus
                    })
                }
                else if (res.data[0].status === "2") {
                    clearInterval(rendCheck)
                    fetchResource()
                }
                else if (res.data[0].status === "3") {
                    clearInterval(rendCheck)
                    fetchResource()
                }
                else if (res.data[0].status === "5" || res.data[0].status === "7" || res.data[0].status === "9") {
                    setState(+res.data[0].status)
                }
            } else {
                clearInterval(rendCheck)
            }
        }).catch((err) => {
            console.log(err)
        })
}

// No Axios Here



// export const addSlides = (data) => (dispatch) => {
//     data[0].transition.simpleTransition = "fade"
//     data.forEach((curElem, index) => {
//         if (curElem.background.type === "color") {
//             if (!curElem.background.src.includes("#")) {
//                 curElem.background.src = "#" + curElem.background.src
//             }
//             if (!curElem.background.poster.includes("#")) {
//                 curElem.background.poster = "#" + curElem.background.poster
//             }
//         }
//         if (curElem.layers === "") {
//             curElem.layers = []
//         }
//         else {
//             let index1 = curElem.layers.findIndex(({ isSelected }) => isSelected === true)
//             if (index1 === -1) {
//                 curElem.layers[0].isSelected = true
//                 if (index > 0) {
//                     curElem.transition.enable = true
//                     curElem.transition.simpleTransition = "fade"
//                 }
//             }
//         }

//     })

//     const ifSelected = data.findIndex(({ isSelected }) => isSelected === "1")
//     if (ifSelected === -1) {
//         data[0].isSelected = "1"
//     }
//     dispatch({ type: "ADD_SLIDES", payload: data })
// }
export const addNewSlide = (data) => (dispatch) => {
    data.transition.enable = true
    data.transition.simpleTransition = "fade"
    dispatch({ type: "NEW_SLIDE_ADD", payload: data })
}
export const removeSlides = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_SLIDES", payload: false })
}

export const textLayerAdd = (slideIndex) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let layer = {
        id: randomId,
        type: "text",
        text: "You Can Edit Text Here",
        style: {
            fontColor: "#FFFFFF",
            fontFamily: "Noto Sans",
            backgroundColor: "",
            fontSize: "22",
            textAlign: "center",
            fontWeight: false,
            fontStyle: false,
            textTransform: "",
            textDecoration: "none",
            lineHeight: 1,
            opacity: 1
        },
        position: {
            left: 150,
            top: 200
        },
        size: {
            width: 300,
            height: 50
        },
        isSelected: true,
        src: "",
        meta: ""
    }
    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}

export const textLayerUpdate = (text, slideIndex, layerIndex) => (dispatch) => {
    const data = { text, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}

export const updateSelectedLayer = (index, selectedSlideIndex) => (dispatch) => {
    const data = { index, selectedSlideIndex }
    dispatch({ type: "UPDATE_LAYER", payload: data })
}

export const removeLayer = (selectedSlide, selectedSlideIndex) => (dispatch) => {
    const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const data = { layerIndex, selectedSlideIndex }
    dispatch({ type: "REMOVE_LAYER", payload: data })
}
export const layerDuplicate = (slideIndex, layerData, layerIndex) => (dispatch) => {
    layerData.id = Math.floor(Math.random() * 1000000000000000).toString()
    const data = { slideIndex, layerData, layerIndex }
    dispatch({ type: "COPY_LAYER", payload: data })
}


export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const updateSlidesData = (slideIndex, index) => (dispatch) => {
    let data = { slideIndex, index }
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}

export const onReorderData = (source, dest) => (dispatch) => {
    let data = { source, dest }
    dispatch({ type: 'ON_REORDER_DATA', payload: data });
}


export const updateVideoThumbnail = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_THUMBNAIL', payload: data });
}

export const removeTtsFromSlides = (selectedSlideIndex) => (dispatch) => {
    const tts = {
        text: "",
        languageId: "",
        voiceId: "",
        translateText: ""
    }
    const data = { tts, selectedSlideIndex }

    dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: data })
}

export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {
    dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })
}


export const updateBg = (file, index, type, hex) => (dispatch) => {
    let data = { file, index }
    if (type === "image") {
        dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
    }
    else if (type === "video") {
        dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
    }
    else {
        data = { file, hex, index }
        dispatch({ type: "UPDATE_BG_COLOR", payload: data })
    }
}

export const addMediaLayer = (type, index, val) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let width = 280, height = 150
    if (type === "video") {
        if (val.width > 720 || val.height > 720) {
            width = Math.floor(val.width / 9)
            height = Math.floor(val.height / 9)
        } else {
            width = Math.floor(val.width / 3)
            height = Math.floor(val.height / 3)
        }
    }

    const data = {
        objData: {
            id: randomId,
            type: type,
            style: {
                fontColor: "#FFFFFF",
                fontFamily: "Noto Sans",
                backgroundColor: "",
                fontSize: "17",
                textAlign: "left",
                fontWeight: false,
                fontStyle: false,
                textTransform: "lowercase",
                textDecoration: "none",
                lineHeight: 1,
                opacity: 1
            },
            position: {
                left: 30,
                top: 30
            },
            size: {
                width: width ? width : 280,
                height: height ? height : 150
            },
            isSelected: true,
            src: val.url,
            meta: ""
        },
        index
    }
    dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const updateMediaLayer = (val, slideIndex, layerIndex, type) => (dispatch) => {
    if (type === "image") {
        if (val.thumbnail) {
            val = val.thumbnail
        } else {
            val = val.url
        }
        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_IMAGE", payload: data })
    } else {

        if (val.width > 720 || val.height > 720) {
            val.width = Math.floor(val.width / 9)
            val.height = Math.floor(val.height / 9)
        } else {
            val.width = Math.floor(val.width / 3)
            val.height = Math.floor(val.height / 3)
        }

        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_VIDEO", payload: data })
    }

}


// LOGO / WATERMARK / SETTING

export const updateStatus = (enable, type) => (dispatch) => {
    const data = { enable, type }
    dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}
export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}
export const updateLogoWatermark = (type, data, dimension) => (dispatch) => {
    const val = { data, dimension }
    dispatch({ type: type, payload: val });
}
export const bgVolumeChange = (type, value) => (dispatch) => {
    dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
    const data = { value, selectedSlideIndex }
    dispatch({ type: type, payload: data });
}
export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}


// CREDIT / INTRO / OUTRO


export const setIntroStatus = (status) => (dispatch) => {
    dispatch({ type: 'SET_INTRO_STATUS', payload: status })
}
export const setOutroStatus = (status) => (dispatch) => {

    dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
}

export const removeIntro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_INTRO' })
}

export const removeOutro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_OUTRO' })
}

export const updateIntroOutro = (type, data) => (dispatch) => {
    dispatch({ type: type, payload: data });
}


// /* Text Styles */

export const updateFontSize = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTSIZE', payload: temp });
}

export const updateFontFamily = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTSTYLE', payload: temp });
}
export const updateTextBold = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBOLD', payload: temp });
}

export const updateTextItalic = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTITALIC', payload: temp });
}

export const updateTextUpper = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTTRANSFORM', payload: temp });
}

export const updateTextLower = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTTRANSFORM', payload: temp });
}

export const updateTextUnderline = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTDECO', payload: temp });
}

export const updateTextStrike = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTDECO', payload: temp });
}


export const updateTextAlign = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTALIGN', payload: temp });
}

export const updateTextLineHeight = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTLINEHEIGHT', payload: temp });
}

export const updateFontColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTCOLOR', payload: temp });
}

export const updateFontBgColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBGCOLOR', payload: temp });
}


export const removeFontBgColor = (selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let data = { selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'REMOVE_TEXT_FONTBGCOLOR', payload: data });
}

export const applyToAllSlide = (data) => (dispatch) => {
    dispatch({ type: 'APPLY_TO_ALL', payload: data });
}


export const updateTtsText = (text, index) => (dispatch) => {
    const data = { text, index }
    dispatch({ type: "UPDATE_TTS1_TEXT", payload: data })
}
export const updateTtsUrl = (val, langData, index) => (dispatch) => {
    const data = { val, langData, index }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Voiceover applied successfully', 'success'));
    dispatch(addSlideDuration(val.duration))
}

export const updateTransLateText = (newText, index) => (dispatch) => {
    const data = { newText, index }
    dispatch({ type: "UPDATE_TEXT_TRANSLATE", payload: data })
}
export const revertTranslate = (index) => (dispatch) => {
    dispatch({ type: "REVERT_TRANSLATE", payload: index })
}

// ======================================================

export const updateVoiceText = (text, index) => (dispatch) => {
    let data = {
        text: text,
        index: index
    }
    dispatch({ type: 'UPDATE_TTS_TEXT', payload: data });
}
export const setTtsData = (meta, selectedSlideIndex, enable) => (dispatch) => {
    const data = { meta, selectedSlideIndex, enable }
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    if (meta.src !== "") {
        dispatch(setAlert('Voiceover applied successfully', 'success'));
        dispatch(addSlideDuration(meta.duration))
    }
}

export const addBgMusic = (url, selectedSlideIndex, dur) => (dispatch) => {
    const data = { url, selectedSlideIndex, dur }
    dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
}

export const addTransition = (name, index) => (dispatch) => {
    let data = { name, index }
    dispatch({ type: "ADD_TRANSITION", payload: data })
    dispatch(setAlert("Transition applied successfully", "success"))
}

export const deleteTile = (deleteIndex, newIndex) => (dispatch) => {
    let data = { deleteIndex, newIndex }
    dispatch({ type: "DELETE_TILE", payload: data })
}
export const removeActionType = () => (dispatch) => {
    dispatch({ type: "REMOVE_ACTION_TYPE" })
}


// LAYERS EDITABLE

export const resizeLayer = (w, h, layerI, slideI) => (dispatch) => {
    const data = { w, h, layerI, slideI }
    dispatch({ type: "RESIZE_LAYER", payload: data })
}

export const rePositionLayer = (t, l, layerI, slideI) => (dispatch) => {
    const data = { t, l, layerI, slideI }
    dispatch({ type: "REPOSITION_LAYER", payload: data })
}
export const updateLayerPos = (slideIndex, layerIndex, place) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    if (place === "down") {
        dispatch({ type: "LAYER_DOWN", payload: data })
    } else {
        dispatch({ type: "LAYER_UP", payload: data })
    }
}