import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { onCreatePress, fetchPress, onSavePress } from "../../Actions/ResourceAction";
import queryString from "query-string";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { onGetClient, } from "../../Actions/ClientsAction";
import { IoMdArrowRoundBack } from "react-icons/io"


const CreateBlog = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const campId = queryString.parse(location.search).id
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)

    const [loader, setLoader] = useState({
        createLoader: false,
        saveLoader: false
    })
    const [show, setShow] = useState(false)
    const [content, setContent] = useState("")
    const [status, setStatus] = useState('')
    const [blog, setBlog] = useState('')
    const [keyword, setKeyWord] = useState({
        category: "",
        topic: "",
        company: ''
    })
    const [id, setId] = useState({
        aId: "",
        cId: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setKeyWord({
            ...keyword,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            createLoader: true
        })
        let data = {
            id: campId,
            //companyName:clientData.company,
            category: keyword.category,
            topic: keyword.topic,
            company: keyword.company
        }
        dispatch(onCreatePress(data, setStatus, loader, setLoader))
    }

    const handleBackBtn = () => {
        if (id.aId && id.cId) {
            navigate(`/agency/create-client?id=${id.aId}&cId=${id.cId}`)
        }
    }

    const viewPress = () => {
        let data = {
            id: campId,
        }
        dispatch(fetchPress(data, setShow, setBlog, loader, setLoader, setContent, setId, id, keyword, setKeyWord))
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        let data = {
            id: campId,
            aiContent: blog,
        }
        dispatch(onSavePress(data, loader, setLoader, navigate, id))
    }

    useEffect(() => {
        if (campId) {
            viewPress()
        }
    }, [campId, status])

    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])

    return (
        <>
            <div className="container">
                <div className="business-wrap">
                    <div className="business-top">
                        <h2>Create Press Release</h2>
                        <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                    </div>

                    <div className="business-list">
                        <div className="proImage withDesc">
                            <div className="proImage-holder">
                                <img src={clientData.image} alt="" />
                            </div>
                            <div className="proImage-txt">
                                <p><strong>{clientData.name}</strong></p>
                                <p>{clientData.email}</p>
                                <p>{clientData.company}</p>
                            </div>
                        </div>
                    </div>



                    <form onSubmit={handleSubmit}>
                        <div className="business-list">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Category</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Category</label>
                                            <input className="inpLabel-inp"
                                                placeholder="Enter category keyword"
                                                type="text"
                                                name="category"
                                                value={keyword.category}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Company Name</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Name of the Company</label>
                                            <input className="inpLabel-inp"
                                                placeholder="Enter category keyword"
                                                type="text"
                                                name="company"
                                                value={keyword.company}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Topic for press release</span>
                                        <div className="inpLabel">
                                            <label htmlFor="">Topic</label>
                                            <input className="inpLabel-inp"
                                                placeholder="Enter topic keyword"
                                                type="text"
                                                name="topic"
                                                value={keyword.topic}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="btn-sec text-right">
                                    <button className="demoLink" type="submit" >
                                        {loader.createLoader ? <>Creating Press Article <i className="fa fa-spinner fa-spin mx-1" /></> : "Create Press Article"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                    {show ? <div className="business-list">
                        <div className="inpLabelWrap">
                            <span className="labelTxt">Press Release</span>
                            <div className="inpLabel">
                                <label htmlFor="">Below is your Press Release Article</label>
                                <textarea className="inpLabel-inp blog_textarea"
                                    name=""
                                    id=""
                                    placeholder="Add Article"
                                    onChange={(e) => setBlog(e.target.value)} value={blog}
                                >

                                </textarea>

                            </div>
                            <div className="btn-sec text-right">
                                <button className="demoLink" onClick={handleSave}>
                                    {loader.saveLoader ? <>{content === "" ? "Saving" : "Updating"} Press Release <i className="fa fa-spinner fa-spin mx-1" /></> : ` ${content === "" ? "Save" : "Update"} Press Release`}
                                </button>
                            </div>
                        </div>

                    </div> : ""}

                </div>
            </div>


        </>
    )
}

export default CreateBlog;