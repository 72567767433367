import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AutoResponderPopup from "./AutoResponderPopup";
import { randomstring } from 'randomstring-js'
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { onConnectAutoRes, onDeleteAutoRes } from "../../Actions/ClientsAction";
import SweetAlertTwo from "../SweetAlertTwo";

const ConnectReelapps = (props) => {
    const dispatch = useDispatch();
    let location = useLocation()
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId

    const [randomString, setRandomString] = useState(randomstring())
    const [checkStatus, setCheckStatus] = useState(true);
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [info, setInfo] = useState({
        id: -1,
        type: "",
        enable: false
    })
    const deleteIntegration = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAutoRes = (Swal) => {
        let data = {
            id: info.id,
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAutoRes(data, props.fetchIntegration, Swal))
    }

    const handleShowAutoRespond = () => {
        if (props.type === "mv" || props.type === "si") {
            handleShow()
        } else {
            let data = {
                type: props.type,
                agencyId: aId,
                clientId: cId,
                key: randomString
            }
            setLoader(true)
            dispatch(onConnectAutoRes(data, checkStatus, setCheckStatus, randomString, setLoader))
        }
    }
    return (
        <>
            <div className="connection-box mt-3" id={props.type}>

                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                        <img src={props.icon} width="56px" alt={props.name} />
                    </div>
                    <div className="connection-txt">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>{props.name} {item?.email}</h6>
                                                    <p className="m-0">Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button
                                                        onClick={(e) => deleteIntegration(item.id)}
                                                        type="button"
                                                        className="btn-change7"
                                                    >Disconnect</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                <button onClick={(e) => handleShowAutoRespond(e)} className="btn-change7">{loader ? <>Connecting <i className="fa fa-spinner fa-spin ml-1" /> </> : 'Connect New Account'}</button>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white">
                                        <div className="youtube-content">
                                            <h6>{props.name}</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account">
                                        <button onClick={(e) => handleShowAutoRespond(e)} className="btn-change7">{loader ? <>Connecting <i className="fa fa-spinner fa-spin ml-1" /> </> : 'Connect'}</button>
                                    </div>
                                </div>
                        }

                    </div>
                </div>

                <div className="row d-none">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className={`connection-img ${props.data.length ? '' : 'disable-img'}`}>
                            <img src={props.icon} width="56px" alt={props.name} />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div key={index} className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>{props.name} {item?.email}</h6>
                                                <p className="m-0">Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button
                                                     onClick={(e) => deleteIntegration(item.id)}
                                                    type="button"
                                                    className="btn-change7"
                                                >Disconnect</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>{props.name}</h6>
                                        <p className="m-0">Not Connected</p>
                                    </div>
                                </div>
                        }

                        <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                            <button onClick={(e) => handleShowAutoRespond(e)} className="btn-change7">{loader ? <>Connecting <i className="fa fa-spinner fa-spin ml-1" /> </> : 'Connect'}</button>
                        </div>
                    </div>
                </div>
            </div>

            <AutoResponderPopup
                show={show}
                handleClose={handleClose}
                name={props.name}
                type={props.type}
            />

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAutoRes}
                onCancel={handleCancle}
            />

        </>
    )
}

export default ConnectReelapps;