import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { SlCheck, SlClose } from "react-icons/sl"
import { onFetchStaffData } from "../../Actions/StaffAction";
import { useDispatch } from "react-redux";
import StaffCard from "./StaffCard";

const Staff = () => {
    const dispatch = useDispatch();

    const [staff, setStaff] = useState([])
    const [loader, setLoader] = useState(false)
    const [search, setSearch] = useState({
        filter: "",
        member: ""
    })

    const fetchStaffData = () => {
        setLoader(true)
        let data = {}
        dispatch(onFetchStaffData(data, setStaff, setLoader))
    }

    const handleSearch = (e) => {
        const { value } = e.target
        setSearch({
            ...search,
            filter: value
        })
    }

    const handleSelect = (e)=>{
      const {value} = e.target
      if(value===""){
        setSearch({...search,member: ""})

      }else if (value="0"){
        setSearch({...search,member: "0" })

      }else if (value="1"){
        setSearch({ ...search,member: "1"})
      }
    }

    useEffect(() => {
        fetchStaffData()
    }, [])

    useEffect(() => {
        if (search.filter === "" ) {
            fetchStaffData()
        } else {
            const filteredStaff = staff
            // .filter((elem)=>{
            //     if(search.member===""){
            //         return fetchStaffData()
            //     }else{
            //         let data = elem.status===search.member
            //         return data;
            //     }
            // })
            .filter((elem) =>
                elem.name.toLowerCase().includes(search.filter.toLowerCase())
            );
            setStaff(filteredStaff);
        }
    }, [search]);

    return (
        <>
            <TitleBar title="Staff" />
            <Navbar />


            <section className="siteWrap">

                <section className="staffSec staffPage">
                    <div className="container">
                        <div className="staffSec-wrap">

                            <div className="convSec-search">
                                <div className="convSec-search-single">
                                    <label htmlFor="">Team Member</label>
                                    <select name="" id="" className="ml-3" onChange={handleSelect}>
                                        <option value="">All</option>
                                        <option value="0">Option-2</option>
                                        <option value="1">Option-3</option>
                                    </select>
                                </div>
                                <div className="convSec-search-single ml-5">
                                    <label className="mr-3" htmlFor="">Staff</label>
                                    <div className="search-bar">
                                        <span><BsSearch /></span>
                                        <input type="text" placeholder="Search Staff" className="ml-3" value={search.filter} onChange={handleSearch} />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                {
                                    staff.length > 0 ?
                                        staff.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <StaffCard
                                                        curElem={curElem}
                                                        index={index}
                                                        fetchStaffData={fetchStaffData}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : ""
                                }

                                <div className="text-center mt-1 text-light"  >
                                    {staff.length == 0 ?
                                        loader ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} />
                                            :
                                            `"No staff created yet!"` :
                                        ""}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </section>

            <Footer />
        </>
    )
}

export default Staff;