import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const fetchAccData = (setTeamData, ) => (dispatch, getState) => {
    commonAxios("fetch-team", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTeamData(res.data.team_members)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const addAccData = (userDetails, setUserDetails, fetchClientTeam, setLoader) => (dispatch, getState) => {
    commonAxios("create-client", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchClientTeam()
                setUserDetails({
                    ...userDetails,
                    type: 'team_member',
                    email: '',
                    name: '',
                    password: ''
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })

}

export const onEditClient = (accountDetails, fetchClientTeam, setLoader,setToggle) => (dispatch, getState) => {
    commonAxios("edit-client-account", accountDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
            setToggle(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}


export const onDeleteAcc = (data, fetchClientTeam, setLoader, Swal) => (dispatch, getState) => {
    commonAxios("delete-team", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                Swal.close()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, 'danger'))
            setLoader(false);
        })

}
