import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai"
import { SlCheck, SlClose } from "react-icons/sl"

import { BiConversation, BiTrash, BiEdit, BiVolumeFull, BiEqualizer } from "react-icons/bi";
import { FaMicrophone } from "react-icons/fa";


const Chat = () => {
    return (
        <>
            <TitleBar title="Chat" />
            <Navbar />
            <section className="chat-wrap">
               <div className="chat-left">
                  <div className="new-chat">
                    <button className="demoLink full mt-0"><BiConversation/> New Chat</button>
                  </div>

                  <div className="chat-scroll">
                    <div className="chat-list">
                        <div className="chat-list-single">
                            <p>What’s the most significant change at your significant change at your</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Social care and social services</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Library and information science</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Analytical music therapy</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Transpersonal psychology</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Best way to stay motivated and complete goals?</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Biochemistry</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Tell me about infancy and early childhood</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>What’s the most significant change at your significant change at your</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Social care and social services</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Library and information science</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Analytical music therapy</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Transpersonal psychology</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Best way to stay motivated and complete goals?</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Biochemistry</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                        <div className="chat-list-single">
                            <p>Tell me about infancy and early childhood</p>
                            <div className="chat-list-action">
                                <button><BiEdit/></button>
                                <button><BiTrash/></button>
                            </div>
                        </div>
                    </div>
                  </div>

                  <div className="clear-conv">
                    <button className="btnGrey full"><BiTrash/> Clear conversations</button>
                  </div>
               </div>

               <div className="chat-right">

                  <div className="chat-area">
                    <div className="chat-area-main">
                    <div className="chat-single bot">
                        <div className="bot-name"><img src={require('../../images/bot-img.png')} /></div>
                            <div className="chat-txt">
                                <div className="chat-txt-in">
                                    <p>Lorem ipsum dolor sit amet consectetur. Amet nulla feugiat turpis nibh. Metus arcu porta habitasse eleifend feugiat egestas quis. Facilisi pellentesque pellentesque dui purus tellus quisque posuere dignissim. Vitae aenean pellentesque sed aliquet faucibus viverra. Massa adipiscing vitae habitant imperdiet porttitor aliquet tincidunt nunc. Eget nulla ante sed ut auctor pulvinar. </p>
                                </div>
                            </div>
                        </div>
                        <div className="chat-single">
                            <div className="chat-txt">
                                <div className="chat-txt-in">
                                    <p>Lorem ipsum dolor sit amet consectetur. Amet nulla feugiat turpis nibh. Metus arcu porta habitasse eleifend feugiat egestas quis. Facilisi pellentesque pellentesque dui purus tellus quisque posuere dignissim. Vitae aenean pellentesque sed aliquet faucibus viverra. Massa adipiscing vitae habitant imperdiet porttitor aliquet tincidunt nunc. Eget nulla ante sed ut auctor pulvinar. </p>
                                </div>
                            </div>
                            <div className="chat-name"><img src="https://images.pexels.com/photos/2080383/pexels-photo-2080383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        </div>
                        <div className="chat-single bot">
                        <div className="bot-name"><img src={require('../../images/bot-img.png')} /></div>
                            <div className="chat-txt">
                                <div className="chat-txt-in">
                                    <p>Lorem ipsum dolor sit amet consectetur. Amet nulla feugiat turpis nibh. Metus arcu porta habitasse eleifend feugiat egestas quis. Facilisi pellentesque pellentesque dui purus tellus quisque posuere dignissim. Vitae aenean pellentesque sed aliquet faucibus viverra. Massa adipiscing vitae habitant imperdiet porttitor aliquet tincidunt nunc. Eget nulla ante sed ut auctor pulvinar. </p>
                                </div>
                            </div>
                        </div>
                        <div className="chat-single">
                            <div className="chat-txt">
                                <div className="chat-txt-in">
                                    <p>Lorem ipsum dolor sit amet consectetur. Amet nulla feugiat turpis nibh. Metus arcu porta habitasse eleifend feugiat egestas quis. Facilisi pellentesque pellentesque dui purus tellus quisque posuere dignissim. Vitae aenean pellentesque sed aliquet faucibus viverra. Massa adipiscing vitae habitant imperdiet porttitor aliquet tincidunt nunc. Eget nulla ante sed ut auctor pulvinar. </p>
                                </div>
                            </div>
                            <div className="chat-name"><img src="https://images.pexels.com/photos/2080383/pexels-photo-2080383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        </div>
                        <div className="chat-single bot">
                        <div className="bot-name"><img src={require('../../images/bot-img.png')} /></div>
                            <div className="chat-txt">
                                <div className="chat-txt-in">
                                    <p>Lorem ipsum dolor sit amet consectetur. Amet nulla feugiat turpis nibh. Metus arcu porta habitasse eleifend feugiat egestas quis. Facilisi pellentesque pellentesque dui purus tellus quisque posuere dignissim. Vitae aenean pellentesque sed aliquet faucibus viverra. Massa adipiscing vitae habitant imperdiet porttitor aliquet tincidunt nunc. Eget nulla ante sed ut auctor pulvinar. </p>
                                </div>
                            </div>
                        </div>
                        <div className="chat-single">
                            <div className="chat-txt">
                                <div className="chat-txt-in">
                                    <p>Lorem ipsum dolor sit amet consectetur. Amet nulla feugiat turpis nibh. Metus arcu porta habitasse eleifend feugiat egestas quis. Facilisi pellentesque pellentesque dui purus tellus quisque posuere dignissim. Vitae aenean pellentesque sed aliquet faucibus viverra. Massa adipiscing vitae habitant imperdiet porttitor aliquet tincidunt nunc. Eget nulla ante sed ut auctor pulvinar. </p>
                                </div>
                            </div>
                            <div className="chat-name"><img src="https://images.pexels.com/photos/2080383/pexels-photo-2080383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                        </div>
                    </div>
                  </div>

                  <div className="chat-area-bottom">
                    <div className="chat-area-bottom-wrap">
                        <div className="chat-bottom-bar">
                            <div className="chat-bottom-left"><button className="demoLink full mt-0"><FaMicrophone/> Voice</button></div>
                            <div className="chat-bottom-right">
                                <div className="lang-select">
                                    <select name="" id="">
                                        <option value="">English</option>
                                    </select>
                                </div>
                                <button><BiVolumeFull/></button>
                                <button className="alt"><BiEqualizer/></button>
                            </div>
                        </div>
                        <div className="chat-inp">
                            <span className="chat-icon"><img src={require('../../images/chat-icon.png')} /></span>
                            <input type="text" placeholder="Type Something..." />
                            <span className="chat-send"><img src={require('../../images/chat-send.png')} /></span>
                        </div>
                        <div className="option-group">

                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="">Output In</label>
                                    <select name="" id="">
                                        <option value="">Option</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="">Tone</label>
                                    <select name="" id="">
                                        <option value="">Option</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="">Writing Style</label>
                                    <select name="" id="">
                                        <option value="">Option</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor=""></label>
                                    <select name="" id="">
                                        <option value="">Option</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
               </div>

            </section>

        </>
    )
}

export default Chat;