import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onFetch, onGetClient } from "../../Actions/ClientsAction";
import queryString from "query-string";
import Template from "./Template";
import KeywordModal from "./Modals/KeywordModal";
import { IoMdArrowRoundBack } from "react-icons/io"

const CreateLandingPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    const [templateList, setTemplateList] = useState([])
    const [searchKey, setSearchKey] = useState("")
    const [loader, setLoader] = useState({
        pageLoader: true,
    })

    const [state, setState] = useState({
        keyword: "",
        templateId: 1,
        agencyId: aId,
        clientId: cId
    })

    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        }
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    const getTemplate = () => {
        let ep = "list-template"
        let data = {}
        dispatch(onFetch(ep, data, setTemplateList))
    }

    useEffect(() => {
        getTemplate()
    }, [])

    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])


    return (

        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <div className="container">
                    <div className="business-wrap">
                        <div className="business-top">
                            <h2>Create Landing Page</h2>
                            <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                        </div>

                        <div className="business-list">
                            <div className="proImage withDesc">
                                <div className="proImage-holder">
                                    <img src={clientData.image} alt="" />
                                </div>
                                <div className="proImage-txt">
                                    <p><strong>{clientData.name}</strong></p>
                                    <p>{clientData.email}</p>
                                    <p>{clientData.company}</p>
                                </div>
                            </div>
                        </div>

                        <div className="business-list">
                            {/* <div className="row">
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Industry</span>
                                        <div className="inpLabel">
                                            <label>Industry</label>
                                            <select
                                                className="inpLabel-inp"
                                                onChange={handleChange}
                                            >
                                                <option style={{ fontWeight: "bold", fontSize: 15, color: "#444242" }} value="">Select Industry</option>
                                                {industry.length > 0 ?
                                                    industry.map((curElem, index) => {
                                                        return (
                                                            <option className="text-capitalize" key={index} value={curElem.id}>{curElem.name}</option>
                                                        )
                                                    })
                                                    : ""}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="business-search justify-content-end mt-4">
                                <div className="business-search-inp">
                                    <span className="searchIcon"><FiSearch /></span>
                                    <input
                                        className="inpSearch withIcon"
                                        type="text"
                                        placeholder="Search Template"
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="list-template">
                                <div className="row">
                                    {templateList.length > 0 ?
                                        templateList.filter((temp) => {
                                            return temp.name.toLowerCase().includes(searchKey.toLowerCase())
                                        }).map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Template
                                                        curElem={curElem}
                                                        setState={setState}
                                                        state={state}
                                                        thumbnail={curElem.thumbnail}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : ""
                                    }
                                </div>
                            </div>

                            <div className="btn-sec text-right">
                                <span onClick={handleShow} className="demoLink">Next</span>
                            </div>

                        </div>
                    </div>
                </div>
                <KeywordModal
                    state={state}
                    setState={setState}
                    show={showModal}
                    handleClose={handleClose}
                />
            </>
    )
}

export default CreateLandingPage;