import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi";
import { HiDocumentDuplicate } from 'react-icons/hi'
import { useDispatch } from 'react-redux';
import SweetAlertTwo from '../SweetAlertTwo';
import { onDeleteAg, onDuplicateAgency } from '../../Actions/AgencyAction';

const AgencyRowData = ({ curElem, fetchAgencyList }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)


    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })
    const onDeleteAgency = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAgencyData = (Swal) => {
        let data = {
            id: info.id
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAg(data, fetchAgencyList, Swal))
    }
    const handleDuplicate = () => {
        let data = {
            id: curElem.id
        }
        setLoader(true)
        dispatch(onDuplicateAgency(data, fetchAgencyList, setLoader))
    }

    return (
        <>
            <tr>
                <td>
                    <div className="proThumb">
                        <img src={curElem.logo} alt={curElem.name} />
                    </div>
                </td>
                <td>{curElem.name}</td>
                <td>{curElem.email}</td>
                <td><span className={`pMode ${curElem.mode === "0" ? "sandbox" : "live"}`}>{curElem.mode === "0" ? "Inactive" : "Active"}</span></td>
                <td>{curElem.totalClient}</td>
                <td>
                    <span
                        className="roundBox"
                        onClick={() => handleDuplicate()}
                        title='Clone'
                    >
                        {loader ? <i className="fa fa-spinner fa-spin" style={{ color: "#d03e3e" }} /> : <HiDocumentDuplicate />}

                    </span>
                 
                    <Link
                        className="roundBox"
                        to={`/agency/create-agency?id=${curElem.id}`}
                        title='Edit'
                    >
                        <FiEdit />
                    </Link>
                    <span
                        className="roundBox"
                        onClick={() => onDeleteAgency(curElem.id)}
                        title='Delete'
                    >
                        <FiTrash2 />
                    </span>

                </td>

            </tr>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAgencyData}
                onCancel={handleCancle}
            />
        </>
    )
}

export default AgencyRowData