import { commonAxios } from "../Global/CommonAxios"
import { onFetchIntegrations } from "./AgencyAction"
import { setAlert } from "./AlertAction"
import { spendUserCredit } from "./ResourceAction"

export const onfecthClients = (data, setState, setLoader) => (dispatch, getState) => {
    commonAxios("list-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCreateClient = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/agency/create-client?id=${data.agencyId}&cId=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onGetClient = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("view-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_CLIENT_DATA", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (loader) {
                setLoader({
                    ...loader,
                    pageLoader: false
                })
            }
        }).catch((err) => {
            console.log(err)
            if (loader) {
                setLoader({
                    ...loader,
                    pageLoader: false
                })
            }
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onUpdateClient = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onDeleteClient = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            Swal.close()
        })
}

export const onUpdatePass = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-client-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                passwordLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                passwordLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onFetch = (ep, data, setState) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (ep === "list-industry") {
                    setState(res.data.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        }
                        else {
                            return -1
                        }
                    }))
                } else {
                    setState(res.data.reverse())
                }

            } else {
                setState([])
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onSubmitLanding = (data, loader, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/agency/edit-landing-page?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCreateImage = (data, setImageData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImageData(res.data)
                dispatch(spendUserCredit())
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

//Landing Data Axios

export const onFetchLanding = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("view-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_GET_LANDING", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                pageLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                pageLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onfetchImages = (data, imageList, setImageList, setMsg, loader, setLoader) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...imageList]
                setImageList(arr.concat(res.data))
            } else {
                setMsg(res.msg)
            }
            setLoader({
                ...loader,
                fetchLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetchLoader: false
            })
        })
}

export const onUploadImageLibrary = (data, fecthData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fecthData()
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
            setLoader({
                ...loader,
                imageLoader: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                imageLoader: false
            })
            console.log(err)
        })
}

export const onFecthUploadImage = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                fetchloader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetchloader: false
            })
            console.log(err)
        })
}

export const onDeleteImage = (data, fecthData, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fecthData()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            Swal.close()
        })
}

export const onFetchAutoResponderList = (data, setState) => (dispatch, getState) => {
    commonAxios("autoresponder-integration-list", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onUpdateLanding = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/agency/create-client?id=${data.agencyId}&cId=${data.clientId}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submitLoader: false
            })
            console.log(err)
        })
}

export const onFetchResourcesList = (data, setState, setLoader) => (dispatch, getState) => {
    commonAxios("list-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onConnectAutoRes =
    (data, checkStatus, setCheckStatus, randomString, setLoader) =>
        (dispatch, getState) => {
            commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
                .then((res) => {
                    if (res.status) {
                        let myWindow = window.open(
                            res.data.outhUrl,
                            "_blank",
                            "width=700, height=500"
                        );
                        let obj = {
                            agencyId: data.agencyId,
                            clientId: data.clientId
                        }
                        let interval = setInterval(() => {
                            if (checkStatus) {
                                dispatch(checkResponse(randomString, interval, myWindow, setCheckStatus, obj)
                                );
                            }
                        }, 5000);
                    } else {
                        dispatch(setAlert(res.msg, "danger"));
                    }
                    setLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoader(false);
                });
        }

export const onConnectMailvio = (data, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                let obj = {
                    agencyId: data.agencyId,
                    clientId: data.clientId,
                    key: data.key
                }
                dispatch(checkResponseMailvio(obj))
                handleClose()

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err);
            setLoader(false)
        })
}

export const checkResponseMailvio = (obj) => (dispatch, getState) => {
    let data = {
        key: obj.key,
    };
    commonAxios("check-network-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchIntegrations(obj))
                dispatch(setAlert(res.msg, "success"));
            } else {

            }
        })
        .catch((err) => {
            console.log(err)
        })
}

export const checkResponse =
    (string1, interval, myWindow, setCheckStatus, obj) =>
        (dispatch, getState) => {
            let data = {
                key: string1,
            };
            commonAxios("check-network-status", data, dispatch, getState().auth.token)
                .then((res) => {
                    if (res.status) {
                        dispatch(onFetchIntegrations(obj))
                        myWindow.close()
                        setCheckStatus(false)
                        dispatch(setAlert(res.msg, "success"));
                        clearInterval(interval);
                    } else {
                        setCheckStatus(false)
                    }
                })
                .catch((err) => {
                    window.close();
                    console.log(err)
                })
        }

export const onDeleteResources = (data, fetchResource, Swal) => (dispatch, getState) => {
    commonAxios("delete-resources", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchResource()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onDeleteAutoRes = (data, fetchIntegration, Swal) => (dispatch, getState) => {
    commonAxios("delete-autoresponder-connection", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchIntegration()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
            dispatch(setAlert(err.message, "danger"))
        })
}




//No Axios Here

export const onChangeClient = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_CLIENT", payload: data })
}

export const onCheckedToggle = (name, checked) => (dispatch) => {
    let data = { name, checked }
    dispatch({ type: "ON_CHECKED_TOGGLE", payload: data })
}

export const onChangeHeader = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_HEADER", payload: data })
}

export const onChangeVideo = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_VIDEO", payload: data })
}

export const onChangeBenefit = (name, value, index) => (dispatch) => {
    let data = { name, value, index }
    dispatch({ type: "ON_CHANGE_BENEFIT", payload: data })
}

export const onAddBenefit = () => (dispatch) => {
    dispatch({ type: "ON_ADD_BENEFIT" })
}

export const onCancleBenefit = (index) => (dispatch) => {
    dispatch({ type: "ON_CANCLE_BENEFIT", payload: index })
}


export const onChangeAbout = (name, value, index) => (dispatch) => {
    let data = { name, value, index }
    dispatch({ type: "ON_CHANGE_ABOUT", payload: data })
}

export const onChangeAboutImage = (url) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_ABOUT_IMAGE", payload: url })
}

export const onAddPara = () => (dispatch) => {
    dispatch({ type: "ON_ADD_PARA" })
}

export const onCancleParagraph = (index) => (dispatch) => {
    dispatch({ type: "ON_CANCEL_PARA", payload: index })
}

export const onChangeTeam = (name, value, index) => (dispatch) => {
    let data = { name, value, index }
    dispatch({ type: "ON_CHANGE_TEAM", payload: data })
}

export const onAddMember = () => (dispatch) => {
    dispatch({ type: "ON_ADD_MEMBER" })
}

export const onCancleMember = (index) => (dispatch) => {
    dispatch({ type: "ON_CANCEL_MEMBER", payload: index })
}

export const onChangeContact = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_CONTACT", payload: data })
}

export const onChangeDays = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_DAYS", payload: data })
}
export const onCheckedContact = (name, checked, value) => (dispatch) => {
    let data = { name, checked, value }
    dispatch({ type: "ON_CHECKED_CONTACT", payload: data })
}

export const onChangeFooter = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_FOOTER", payload: data })
}

