import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onFetchStaffData = (data, setStaff, setLoader) => (dispatch, getState) => {
    commonAxios("view-hire-staff", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setStaff(res.data)
            } else {
                dispatch(res.msg, "danger")
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(err.msg, "danger")
        })
}

export const onChangeStatus = (data, ep, swal,fetchStaffData) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchStaffData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            swal.close()
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            swal.close()
        })

}


// conversation Axios

export const onFetchConversationData = (data, setConversation, setLoader) => (dispatch, getState) => {
    commonAxios("view-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setConversation(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const fetchTeamData = (setTeamData, ) => (dispatch, getState) => {
    commonAxios("fetch-team", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTeamData(res.data.team_members)
            }
        }).catch((err) => {
            console.log(err)
        })
}