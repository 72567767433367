import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { VscDiffAdded, VscDiffRemoved } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onInvoiceSave1, onSetInvoice, onSetInvoiceTable, onUpdateInvoiceData } from "../../../Actions/ResourceAction";
import { onFecthCards } from "../../../Actions/ResourceAction";

const Invoice_1 = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const invoice = useSelector(state => state.resource.invoice)


  const [headingText, setHeadingText] = useState({
    name: "Your Name",
    phoneNo: "123-456-789",
    address: "123,Anywhere city",
    innvoiceNo: "12345",
    date: "16 June 2025",
    tax: "0.00",
    taxActive: true,
    taxPercent: "0%",
    currency: "$",
    lastName: "Your Name",
    name: "Account Name",
    number: "Account Number",
    date: "Duo Date",
    accountName: "Lorem ipsum",
    accountNO: "123-456-789",
    duoDate: "16 June 2025",
    thankyou: "Thank You!"
  });

  const [table, setTable] = useState([
    {
      item: "Cotton",
      quantity: "1",
      unit: "100",
      total: "100",
    },
  ]);

  // const [payIngormation, setheadingText] = useState({
  //   lastName: "Your Name",
  //   name: "Account Name",
  //   number: "Account Number",
  //   date: "Duo Date",
  //   accountName: "Lorem ipsum",
  //   accountNO: "123-456-789",
  //   duoDate: "16 June 2025",
  //   thankyou:"Thank You!"
  // });

  const [total, setTotal] = useState({
    subTotal: 0,
    grandTotal: 0,
  });

  const [btn, setBtn] = useState({
    add: false,
    minus: null,
    taxShow: false,
  });

  const handleChange=(e, type)=> {
    const { value } = e.target;
    setHeadingText({
      ...headingText,
      [type]: value,
    });
    dispatch(onUpdateInvoiceData(type, value,))
  }

  const handleTable = (e, index, type) => {
    // let index = 0;
    const { value } = e.target;
    if (index !== undefined) {
      let newData = [...table];
      // newData[index][type] = value;
      newData[index] = {
        ...newData[index],
        [type]: value,
      }

      if (type === "quantity" || type === "unit") {
        newData[index] = {
          ...newData[index],
          total: (
            parseFloat(newData[index].unit) * +newData[index].quantity
          ).toFixed(2)
        };
      }
      setTable(newData);
      dispatch(onSetInvoiceTable(newData));
    }
  };

  // function handleInfo(e, type) {
  //   const { value } = e.target;
  //   setheadingText({
  //     ...headingText,
  //     [type]: value,
  //   });
  // }

  const handleAddrow = () => {
    setTable([
      ...table,
      {
        // currency:"$",
        item: "Cotton",
        quantity: "1",
        unit: "123",
        total: "123",
      },
    ]);
  };

  const handleRemoveRow = (id) => {
    setTable(table.filter((row, ind) => ind !== id));
  };

  const handleTax = (val, type) => {
    setHeadingText({
      ...headingText,
      [type]: val,
    });
  };

  const addMouseHover = () => {
    setBtn({
      ...btn,
      add: true,
      minus: false,
    });
  };

  const addMouseLeave = () => {
    setBtn({
      ...btn,
      add: false,
      minus: false,
    });
  };

  const removeMouseHover = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: id,
    });
  };

  const removeMouseLeave = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: null,
    });
  };

  useEffect(() => {
    let grandtotal = 0,
      subtotal = 0;
    table.forEach((curElem) => {
      subtotal = subtotal + parseFloat(curElem.total);
    });
    grandtotal = subtotal + parseFloat(headingText.tax);
    if (!headingText.taxActive) {
      grandtotal = grandtotal - parseFloat(headingText.tax);
    }
    setTotal({
      ...total,
      subTotal: subtotal,
      grandTotal: grandtotal,
    });
  }, [table, headingText]);





  useEffect(() => {
    if (invoice.data === "") {
      dispatch(onSetInvoice({ ...headingText, table }))
    } else {
      setHeadingText(invoice.data)
      setTable(invoice.data.table)
    }
  }, [invoice])
  

  return (
    <>
      <div className="invoice_border" style={{ background: "#fff" }}>
        <div classNameName="siteWrap">
          <div className=" inline_editor ">
            <div className=" inline_editor page-content container">
              <div className="container px-0">
                <div className="row mt-4">
                  <div className="col-12 col-lg-12">
                    <div className="invoice_heaing text-end mt-2 mb-5">
                      <h2> INVOICE</h2>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <span
                            style={{
                              color: "#000",
                              fontSize: "19px",
                              fontWeight: "600",
                            }}
                          >
                            BILLED TO:
                          </span>
                        </div>
                        <div
                          style={{
                            color: "#000",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          <div className="my-1">
                            <ContentEditable
                              html={headingText.name}
                              tagName="name"
                              onChange={(e) => handleChange(e, "name")}
                            />
                          </div>
                          <div className="my-1">
                            <ContentEditable
                              html={headingText.phoneNo}
                              tagName="phoneNo"
                              onChange={(e) => handleChange(e, "phoneNo")}
                            />
                          </div>
                          <div className="my-1">
                            <ContentEditable
                              html={headingText.address}
                              tagName="address"
                              onChange={(e) => handleChange(e, "address")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                        <hr className="d-sm-none" />
                        <div className="text-grey-m2">
                          <div className="my-2">
                            <span
                              style={{
                                color: "#000",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            >
                              Invoice No.
                              <ContentEditable
                                html={headingText.innvoiceNo}
                                tagName="innvoiceNo"
                                onChange={(e) => handleChange(e, "innvoiceNo")}
                              />
                            </span>{" "}
                          </div>

                          <div className="my-2">
                            <span
                              style={{
                                color: "#000",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            >
                              <ContentEditable
                                html={headingText.date}
                                tagName="date"
                                onChange={(e) => handleChange(e, "date")}
                              />
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mt-3">
                      <div className="row">
                        <table class="table">
                          <thead
                            onMouseOver={addMouseHover}
                            onMouseLeave={addMouseLeave}
                          >
                            <tr>
                              <th scope="col">Items</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Unit Price</th>
                              <th scope="col">
                                Total
                                {btn.add ? (
                                  <VscDiffAdded
                                    style={{
                                      position: "absolute",
                                      cursor: "pointer",
                                      marginLeft: "5%",
                                    }}
                                    onClick={handleAddrow}
                                  />
                                ) : (
                                  ""
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {table.length > 0 ?
                              table.map((curElem, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onMouseOver={() => removeMouseHover(index)}
                                    onMouseLeave={() => removeMouseLeave(index)}
                                  >
                                    <td>
                                      <ContentEditable
                                        html={curElem.item}
                                        tagName="item"
                                        onChange={(e) =>
                                          handleTable(e, index, "item")
                                        }
                                      />
                                    </td>

                                    <td>
                                      <ContentEditable
                                        html={curElem.quantity}
                                        tagName="quantity"
                                        onChange={(e) =>
                                          handleTable(e, index, "quantity")
                                        }
                                      />
                                    </td>

                                    <td>
                                      <ContentEditable
                                        html={headingText.currency}
                                        tagName="span"
                                        onChange={(e) =>
                                          handleChange(e, "currency")
                                        }
                                      />
                                      <ContentEditable
                                        html={curElem.unit}
                                        tagName="unit"
                                        onChange={(e) =>
                                          handleTable(e, index, "unit")
                                        }
                                      />
                                    </td>

                                    <td>
                                      <ContentEditable
                                        html={headingText.currency}
                                        tagName="span"
                                        onChange={(e) =>
                                          handleChange(e, "currency")
                                        }
                                        
                                      />
                                      {curElem.total}

                                      {btn.minus === index ? (
                                        <VscDiffRemoved
                                          style={{
                                            position: "absolute",
                                            cursor: "pointer",
                                            marginLeft: "35px",
                                          }}
                                          onClick={() => handleRemoveRow(index)}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                );
                              }) : ""}

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row mt-2 mb-5">
                      <div className="col-md-12 ">
                        <div
                          className=" d-flex align-items-end "
                          style={{ marginRight: "40px", flexDirection: "column" }}
                        >
                          <div className="sub_total_wrap d-flex justify-content-center align-items-center">
                            <h5 style={{ fontSize: "17px" }}>Subtotal</h5>
                            <span className="ms-5">
                              <ContentEditable
                                html={headingText.currency}
                                tagName="span"
                                onChange={(e) => handleChange(e, "currency")}
                              />
                              {total.subTotal.toFixed(2)}
                            </span>
                          </div>

                          {headingText.taxActive ? (
                            <div
                              className="sub_total_tex d-flex justify-content-center align-items-center mt-2"
                              onMouseEnter={() =>
                                setBtn({
                                  ...btn,
                                  taxShow: true,
                                })
                              }
                              onMouseLeave={() =>
                                setBtn({
                                  ...btn,
                                  taxShow: false,
                                })
                              }
                            >
                              <h5 style={{ fontSize: "17px" }}>
                                Tax(
                                <ContentEditable
                                  html={headingText.taxPercent}
                                  tagName="taxPercent"
                                  onChange={(e) => handleChange(e, "taxPercent")}
                                />
                                )
                              </h5>
                              <div>
                                <span className="" style={{ marginLeft: "77px" }}>
                                  <ContentEditable
                                    html={headingText.currency}
                                    tagName="span"
                                    onChange={(e) => handleChange(e, "currency")}
                                   
                                  />

                                  <ContentEditable
                                    html={headingText.tax}
                                    tagName="span"
                                    onChange={(e) => handleChange(e, "tax")}
                                  />
                                  {btn.taxShow ? (
                                    <IoCloseCircleOutline
                                      style={{
                                        position: "absolute",
                                        cursor: "pointer",
                                      }}
                                      size={16}
                                      onClick={() =>
                                        handleTax(false, "taxActive")
                                      }
                                      title="Remove Tex"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <hr
                            style={{
                              opacity: "1",
                              marginRight: "-13px",
                              width: "189px",
                              height: "2px",
                            }}
                          />
                          <div className="total_count d-flex justify-content-center align-items-center">
                            <h5 style={{ fontSize: "17px" }}>TOTAL</h5>
                            <span className="" style={{ marginLeft: "58px" }}>
                              <ContentEditable
                                html={headingText.currency}
                                tagName="span"
                                onChange={(e) => handleChange(e, "currency")}
                              />

                              {total.grandTotal.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-12 ">
                        <div className="thankyou_page ">
                          <h5> <ContentEditable
                            html={headingText.thankyou}
                            tagName="span"
                            onChange={(e) => handleChange(e, "thankyou")}
                          /></h5>
                          <span className="mt-3">PAYMENT INFORMATION</span>

                          <ul>
                            <li>
                              {" "}
                              <span>
                                <ContentEditable
                                  html={headingText.lastName}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "lastName")}
                                />
                              </span>
                            </li>

                            <li>
                              <span>
                                {" "}
                                <ContentEditable
                                  html={headingText.name}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "name")}
                                />
                              </span>{" "}
                              :
                              <ContentEditable
                                html={headingText.accountName}
                                tagName="span"
                                onChange={(e) => handleChange(e, "accountName")}
                              />
                            </li>

                            <li>
                              <span>
                                {" "}
                                <ContentEditable
                                  html={headingText.number}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "number")}
                                />
                              </span>{" "}
                              :
                              <ContentEditable
                                html={headingText.accountNO}
                                tagName="span"
                                onChange={(e) => handleChange(e, "accountNO")}
                              />
                            </li>

                            <li>
                              {" "}
                              <span>
                                {" "}
                                <ContentEditable
                                  html={headingText.date}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "date")}
                                />
                              </span>{" "}
                              :{" "}
                              <ContentEditable
                                html={headingText.duoDate}
                                tagName="span"
                                onChange={(e) => handleChange(e, "duoDate")}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};
export default Invoice_1;
