import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
// import { addChapters, addCourse, updateChapterText, updateChapterTextIfFalse } from "../../actions/courseAction";
import { IoReloadSharp } from "react-icons/io5";
import { setAlert } from "../../Actions/AlertAction";
import { onFetchScript, onGenerateSlides, onSaveScript } from "../../Actions/ResourceAction";

const VideoScript = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const scriptRef = useRef();
    const id = queryString.parse(location.search).id
    const campaign = useSelector(state => state.resource.videoCamp)

    const [loader, setLoader] = useState({
        save: false,
        ai: false,
        generate: false,
        pageLoader: false
    })
    const [scriptData, setScriptData] = useState("")
    const [length, setLength] = useState(0)


    useEffect(() => {
        if (scriptData !== "") {
            console.log(scriptData.originalScript)
            let data = []
            if (scriptData.originalScript.includes("¶")) {
                data = scriptData.originalScript.split("¶")
            } else {
                data = scriptData.originalScript.split(".")
            }
            data = data.filter((curElem) => {
                return curElem !== ""
            })
            setLength(data.length)
        } else {
            setLength(0)
        }
    }, [scriptData])

    const handleChange = (e) => {
        setScriptData({
            ...scriptData,
            originalScript: e.target.value
        })
    }

    const fetchScript = () => {
        let data = { "scriptId": id }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onFetchScript(data, setScriptData, loader, setLoader))
    }

    const handleKeyUp = (e) => {
        if (e.ctrlKey && e.keyCode === 13) {
            const [start, end] = [scriptRef.current.selectionStart, scriptRef.current.selectionEnd];
            let textData = scriptData.originalScript
            textData = textData.substring(0, start) + " ¶ " + textData.substring(end, textData.length)
            setScriptData({
                ...scriptData,
                originalScript: textData
            })
        }
    }

    const handelSlideMax = () => {
        dispatch(setAlert("You can have maximum 30 slides in a video.", "danger"))
    }

    const saveScript = () => {
        let slideBreak = "dots"
        if (scriptData.originalScript.includes("¶")) {
            slideBreak = "generate"
        }
        let data = {
            scriptId: id,
            script: scriptData.originalScript,
            type: "save",
            slideBreak: slideBreak
        }
        setLoader({
            ...loader,
            save: true
        })
        dispatch(onSaveScript(data, loader, setLoader))
    }

    const generateSlides = () => {
        let slideBreak = "dots"
        if (scriptData.originalScript.includes("¶")) {
            slideBreak = "generate"
        }
        let data = {
            scriptId: id,
            script: scriptData.originalScript,
            type: "generate",
            slideBreak: slideBreak
        }
        setLoader({
            ...loader,
            generate: true
        })
        dispatch(onGenerateSlides(data, navigate, loader, setLoader, scriptData.videoId))
    }

    useEffect(() => {
        if (id) {
            fetchScript()
        }
    }, [id])


    return (
        <>

            <section className="siteWrapnn">
                <div className="container">
                    <div className="cont-research">
                        <div className="cont-research-head">
                            <div className="research-head-left" style={{ maxWidth: "800px" }}>
                                <h2 className="text-capitalize">
                                    {campaign.name}
                                </h2>
                            </div>
                            <div className="research-head-right">
                                <button
                                    className="backBtn mx-2"
                                    onClick={saveScript}
                                >{loader.save ? <> Saving <i className="fa fa-spinner fa-spin mr-2" /> </> : "Save Script"}</button>
                                {length < 31 ?
                                    <button
                                        className="backBtn research-btn "
                                        onClick={generateSlides}
                                    >
                                        {loader.generate ?
                                            <>
                                                Converting <i className="fa fa-spinner fa-spin mr-2" />
                                            </>
                                            :
                                            "Convert To Video"
                                        }
                                    </button>
                                    :
                                    <button
                                        className="backBtn research-btn "
                                        onClick={() => handelSlideMax()}
                                    >Convert To Video</button>
                                }

                            </div>
                        </div>

                        <div className="cont-research-body modified">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="research-body-right">
                                        <div className="research-block">
                                            <div className="txtEdit">
                                                <div className="txtEdit-top">
                                                    <h5 style={{ fontSize: "18px", fontWeight: "400" }}>To separate slides press<span style={{ color: "#e512c9" }}> Ctrl + Enter</span></h5>
                                                </div>
                                                <div className="txtEdit-main">
                                                    {loader.pageLoader ?
                                                        <div className="text-center" style={{ height: "calc(100vh - 455px)" }}>
                                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#ff6363" }} />
                                                        </div>
                                                        :
                                                        <textarea
                                                            type="text"
                                                            value={scriptData.originalScript}
                                                            name="text"
                                                            onChange={(e) => handleChange(e)}
                                                            placeholder="Write Your Content Here"
                                                            onKeyUp={handleKeyUp}
                                                            ref={scriptRef}
                                                        />}
                                                </div>
                                                <div className="txtEdit-foot">
                                                    <p className="text-right">Slides: {length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VideoScript;