import React from "react";
import { Link } from "react-router-dom";
// import VideoCard from "../project/VideoCard";

const DashboardContent = () => {
    return (
        <>

            <section className="videoProject d-none">
                <div className="container-fluid">
                    <div className="videoProject-top d-none">
                        <h2>Your <span>Business</span></h2>
                        <Link to="/business" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="videoProject-bottom">
                        {/* <VideoCard
                            showRecord={4}
                        /> */}
                    </div>
                </div>
            </section>


            <section className="staffSec">
                <div className="container">
                    <div className="videoProject-top">
                        <h2>Your <span>Staff</span></h2>
                        <Link to="" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="staffSec-wrap">
                        <div className="row">
                            <div className="col-sm-6 col-lg-3">
                                <div className="staff-single">
                                    <div className="staff-img"><img src="https://images.pexels.com/photos/6976943/pexels-photo-6976943.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="staff-txt">
                                        <h6>Dana Emerson</h6>
                                        <Link to="/chat" className="demoLink mt-2" href="">Chat</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="staff-single">
                                    <div className="staff-img"><img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="staff-txt">
                                        <h6>Klare Tyson</h6>
                                        <Link to="/chat" className="demoLink mt-2" href="">Chat</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="staff-single">
                                    <div className="staff-img"><img src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="staff-txt">
                                        <h6>Tony Bowler</h6>
                                        <Link to="/chat" className="demoLink mt-2" href="">Chat</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="staff-single">
                                    <div className="staff-img"><img src="https://images.pexels.com/photos/1130626/pexels-photo-1130626.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="staff-txt">
                                        <h6>Antonina Salt</h6>
                                        <Link to="/chat" className="demoLink mt-2" href="">Chat</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="convSec">
                <div className="container">
                    <div className="videoProject-top">
                        <h2>Your <span>Conversations</span></h2>
                        <Link to="" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="convSec-wrap">
                        <div className="row">
                            <div className="col-sm-6 col-lg-4">
                                <div className="convSec-single">
                                    <div className="convSec-img"><img src="https://images.pexels.com/photos/2040747/pexels-photo-2040747.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="convSec-txt">
                                        <h6>Conversations Title</h6>
                                        <div className="text-center"><a className="demoLink mt-2" href="">View Conversations</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="convSec-single">
                                    <div className="convSec-img"><img src="https://images.pexels.com/photos/344102/pexels-photo-344102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="convSec-txt">
                                        <h6>Conversations Title</h6>
                                        <div className="text-center"><a className="demoLink mt-2" href="">View Conversations</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="convSec-single">
                                    <div className="convSec-img"><img src="https://images.pexels.com/photos/935949/pexels-photo-935949.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    <div className="convSec-txt">
                                        <h6>Conversations Title</h6>
                                        <div className="text-center"><a className="demoLink mt-2" href="">View Conversations</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;