import React from 'react';
import { useDispatch } from 'react-redux';
import { updateSlidesData } from '../../../../Actions/VideoAction';


const Story = ({ slideData }) => {
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const dispatch = useDispatch()
    const updateStoryTile = (index) => {
        if (selectedSlideIndex !== index) {
            dispatch(updateSlidesData(selectedSlideIndex, index))
        }
    }
    return (
        <div className="tabInner">
            <div className="story-block">
                <ul>
                    {slideData.length > 0 ?
                        slideData.map((curElem, index) => {
                            return (
                                <li
                                    className={`${selectedSlideIndex === index ? 'active cursor-pointer' : 'cursor-pointer'}`}
                                    key={index}
                                    onClick={() => updateStoryTile(index)}
                                >
                                    <div className="story-block-single" >
                                        <p className={selectedSlideIndex === index ? "text-light" :  `text-dark`}>{curElem.originalText}</p>
                                    </div>
                                </li>
                            )
                        })
                        : ""}
                </ul>
            </div>
        </div>
    );
};

export default Story;
